import React from "react";

import {
  Grid,
  AppBar,
  IconButton,
  Typography,
  makeStyles,
  useTheme,
} from "@material-ui/core";
// import makeStyles from "@mui/styles/makeStyles";

import Header from "./HeaderOld";
import Summary from "./Summary";

import Breadcrumbs from "./Breadcrumbs";

import { ThemeProvider } from "@material-ui/core/styles";
import { lightTheme } from "../../../Theme";
import ArrowLeft from "../../../icons/ArrowLeft";

const useStyles = makeStyles((theme) => ({
  item: {
    margin: "20px",
  },
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
  title: {
    flexGrow: 1,
  },

  header: {
    padding: "16px 24px 14px 40px",
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    width: 700,
  },
  actionButton: {
    width: 160,
  },
  headingWithArrow: {
    paddingLeft: "38px",
  },
}));

const AnalysisDialog = ({ close, issueData, totalTrips }) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <ThemeProvider theme={lightTheme}>
      <Grid
        container
        style={{ height: "calc(100%)", width: "calc(100%)" }}
        alignItems="flex-start"
        justifyContent="space-evenly"
      >
        <AppBar
          style={{
            position: "relative",
            width: "calc(100%)",
            boxShadow: "none",
          }}
        >
          <Header className={classes.header}>
            <div>
              <Breadcrumbs
                text={"Summary and Analytics"}
                links={[{ name: "Control Tower", url: "/home" }]}
              />

              <Typography
                variant="h3"
                style={{ color: theme.palette.common.black }}
              >
                <div
                  style={{
                    marginTop: 8,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <IconButton
                    style={{ position: "absolute" }}
                    onClick={() => {
                      close();
                    }}
                  >
                    <ArrowLeft fontSize="small" color="primary" />
                  </IconButton>

                  <span id="title" className={classes.headingWithArrow}>
                    Summary and Analytics
                  </span>
                </div>
              </Typography>
            </div>
          </Header>
        </AppBar>
        <Grid item xs={12} style={{ height: "calc(100%)" }}>
          <Summary issueData={issueData} totalTrips={totalTrips} />
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default AnalysisDialog;
