
import { useState } from "react";

const useActive = (initialValue) => {
  const [active, setActive] = useState(initialValue);

  const handleActive = (active) => setActive(active);

  return {
    active,
    handleActive,
  };
};

export default useActive;
