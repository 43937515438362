import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import BarChartIcon from "@material-ui/icons/BarChart";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import List from "@material-ui/core/List";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import axios from "axios";
// import { TransitionProps } from '@material-ui/core/transitions';
import moment from "moment";
import { connect } from "react-redux";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import MenuItem from "@material-ui/core/MenuItem";
import PropTypes from "prop-types";
import TablePagination from "@material-ui/core/TablePagination";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import Tooltip from "@material-ui/core/Tooltip";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";
import { green } from "@material-ui/core/colors";
// import MapView from '../MapView/MapView'
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Button } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Counters from "../counters";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Autocomplete from "@material-ui/lab/Autocomplete";
// import DateRangeIcon from '@material-ui/icons/DateRange';
import RefreshIcon from "@material-ui/icons/Refresh";

import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import Typography from "@material-ui/core/Typography";
import Analysis from "../Analysis/analysis";
import Filter from "./Filter";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { endOfDay, format, isValid, startOfDay, addDays } from "date-fns";
import AnalysisDialog from "./components/AnalysisDialog";

// import 'react-date-range/dist/styles.css'; // main style file
// import 'react-date-range/dist/theme/default.css'; // theme css file
// import { DateRangePicker } from 'react-date-range';

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

// const Transition = React.forwardRef(function Transition(
//     props: TransitionProps & { children?: React.ReactElement },
//     ref: React.Ref<unknown>,
//   ) {
//     return <Slide direction="up" ref={ref} {...props} />;
// });

const table_header = [
  { header: "Status", key: "", data: false },
  { header: "Actions", key: "", data: false },
  { header: "LR No.", key: "invoice", data: true },
  { header: "Mobile", key: "tel", data: true },
  { header: "Tag", key: "issue_tag", data: true },
  { header: "Start Date and Time", key: "startTime", data: true },
  { header: "Source", key: "src", data: true },
  { header: "Destination", key: "dest", data: true },
  { header: "Vehicle", key: "truck_number", data: true },
  { header: "Transporter", key: "transporter", data: true, sorting: true },
  { header: "Duration", key: "issue_value", data: false },
  { header: "Latest Remark", key: "remark", data: false },
];

const ops_table_header = [
  { header: "Status", key: "", data: false },
  { header: "Actions", key: "", data: false },
  { header: "Client", key: "user", data: true },
  { header: "LR No.", key: "invoice", data: true },
  { header: "Mobile", key: "tel", data: true },
  { header: "Tag", key: "issue_tag", data: true },
  { header: "Start Date and Time", key: "startTime", data: true },
  { header: "Source", key: "srcname", data: true },
  { header: "Destination", key: "destname", data: true },
  { header: "Vehicle", key: "truck_number", data: true },
  { header: "Transporter", key: "transporter", data: true },
  { header: "Test Field", key: "", data: false },
  // {header: 'Last Tracked', key: 'value', data: false},
  { header: "Latest Remark", key: "", data: false },
];

const ct_report_headers = [
  { text: "Transporter", value: "transporter" },
  { text: "Driver Phone", value: "driver_phone", calculate: "driver_phone" },
  { text: "Vehicle Number", value: "truck_number" },
  { text: "Start Time", value: "startTime" },
  { text: "Source", value: "src" },
  { text: "Destination", value: "dest" },
  {
    text: "Last Location",
    value: "last_known_loc",
    calculate: "last_known_loc",
  },
  { text: "Last Known Address", value: "last_known_address" },
  { text: "Delay(Hours)", value: "delay_hrs", calculate: "delay_hrs" },
  { text: "Current Halt Duration", value: "halt_hrs", calculate: "halt_hrs" },
  { text: "Tag", value: "issue_tag" },
  { text: "Reasons", value: "", calculate: "reasons" },
  {
    text: "Driver/Transporter comments  -  Reason for delay",
    value: "",
    calculate: "reason for delay",
  },
  // { text: 'Expectation', value: '' },
];

const total_trips_report_headers = [
  { text: "Transporter", value: "transporter" },
  { text: "Driver Phone", value: "tel" },
  { text: "Vehicle Number", value: "truck_number" },
  { text: "Start Time", value: "startTime" },
  { text: "Source", value: "src" },
  { text: "Destination", value: "dest" },
  { text: "Current Address", value: "current_address" },
  { text: "Last Tracked At", value: "last_tracked_at" },
  { text: "ETA", value: "eta" },
];

// ops_table_header.forEach((obj) => {
//   obj.width = obj.width ? obj.width : 100 / table_header.length + "%";
// });

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    maxHeight: "700px",
  },
  root: {
    margin: "15px 2%",
  },
  table: {
    minWidth: 650,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  tableRow: {
    cursor: "pointer",
  },
  tableCell: {
    borderBottom: "5px solid #f7f7f7",
    padding: "0px",
    color: "#43425d",
    height: "80pxpx",
  },
  tableCellHeader: {
    borderBottom: "5px solid #f7f7f7",
    padding: "5px",
  },
  homeHeading: {
    backgroundColor: "#f0f7ff",
    // padding: '3px',
  },
  loaderWrapper: {
    display: "flex",
    alignItems: "center",
  },
  loader: {
    color: green[500],
    position: "relative",
    left: -24,
    zIndex: 1,
  },
  tripStatusField: {
    "& .MuiSelect-filled": {
      paddingTop: "10px",
    },
  },
  icons: {
    cursor: "pointer",
    fontSize: "28px",
    margin: "0px 10px",
  },
  dateContainer: {
    position: "absolute",
    boxShadow: "5px 5px 50px 5px gray",
    zIndex: "10",
    top: "30%",
    left: "40%",
    background: "white",
  },
  actionsDialog: {
    backgroundColor: "#f0f7ff",
  },
  optionsHeading: {
    color: "#43425D",
    fontWeight: "500",
    fontSize: "14px",
  },
  High: {
    color: "#FE5858",
    borderColor: "#FE5858",
    margin: "10px",
    width: "140px",
    "&:focus": {
      outline: "none",
    },
    fontSize: "18px",
    "&::after": {
      content: "attr(value)",
      position: "absolute",
      top: "60%",
      left: "-10%",
      background: "white",
      color: "#43425D",
      "font-family": "'Rubik', sans-serif",
      "border-radius": "43%",
      "font-size": "12px",
      padding: "5px",
    },
  },
  Medium: {
    color: "#EDBD00",
    borderColor: "#EDBD00",
    margin: "10px",
    width: "140px",
    "&:focus": {
      outline: "none",
    },
    fontSize: "18px",
    "&::after": {
      content: "attr(value)",
      position: "absolute",
      top: "60%",
      left: "-10%",
      background: "white",
      color: "#43425D",
      "font-family": "'Rubik', sans-serif",
      "border-radius": "43%",
      "font-size": "12px",
      padding: "5px",
    },
  },
  Low: {
    color: "#009CB5",
    borderColor: "#009CB5",
    margin: "10px",
    width: "140px",
    "&:focus": {
      outline: "none",
    },
    fontSize: "18px",
    "&::after": {
      content: "attr(value)",
      position: "absolute",
      top: "60%",
      left: "-10%",
      background: "white",
      color: "#43425D",
      "font-family": "'Rubik', sans-serif",
      "border-radius": "43%",
      "font-size": "12px",
      padding: "5px",
    },
  },
  pointer: {
    cursor: "pointer",
  },
  issueCountButton: {
    "&:focus": {
      outline: "none",
    },
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const formatDate = (date) => {
  if (date && isValid(new Date(date))) {
    return format(new Date(date), "dd/MM/yyyy @hh:mm a");
  }
  return "N.A";
};

let defaultCheckedColumn = ["Status", "Actions", "Mobile", "Vehicle"];
function Home(props) {
  const [rows, setRows] = React.useState([]);
  const [pureRows, setPureRows] = React.useState([]);

  const [search, setSearch] = React.useState("");
  const [filterData, setFilterData] = useState({});

  const handleApplyFilter = () => {
    setHitApply(!hitApply);
  };
  const handleClearFilter = () => {
    setFilterData({});
    setHitApply(!hitApply);
  };

  const [getFilters, setGetFilters] = useState({});

  const changeKeys = {
    Source: "src",
    Destination: "dest",
    Transporter: "transporter",
    "Trip Status": "trip_status",
    "Movement Type": "movement_type",
    Zone: "zone",
    City: "city",
    "Client Name": "client_name",
  };

  useEffect(() => {
    axios
      .get(
        "https://da93wz82kc.execute-api.ap-south-1.amazonaws.com/api/v1/trips/get_filters",
        {
          headers: {
            Authorization: `Bearer ${props.token}`,
          },
        }
      )
      .then((res) => setGetFilters(res?.data));
  }, []);
  const [state, setState] = React.useState({
    actual_headers: {
      // Now controlled from backend api
      // Status: true,
      // "Sub Tag": true,
      // "LR No.": true,
      // Source: true,
      // "Shipment Number": true,
      // "Load ID": true,
      // "Start Date and Time": true,
      // Destination: true,
      // Vehicle: true,
      // Mobile: true,
      // Transporter: true,
      // "Last Tracked": true,
      // Tag: true,
      // Duration: true,
      // "Latest Remark": true,
      // Actions: true,
    },
    ops_actual_headers: {
      Status: true,
      "Sub Tag": true,
      "Start Date and Time": true,
      Client: true,
      "LR No.": true,
      Source: true,
      Destination: true,
      Vehicle: true,
      Mobile: true,
      Transporter: true,
      "Last Tracked": true,
      "Test Field": true,
      Tag: true,
      "Latest Remark": true,
      Actions: true,
    },
    report: {
      startDate: null,
      endDate: null,
      dialog: false,
    },
    date: {
      endDate: new Date(),
      startDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth() - 1,
        new Date().getDate()
      ),
      key: "selection",
    },
    actualHeaderVariable:
      props.client === "INTUGINE OPS" ? "ops_actual_headers" : "actual_headers",
    tags: [],
    priority: {
      HIGH: 0,
      MEDIUM: 0,
      LOW: 0,
    },
    issuesCounter: [],
    totalTrips: 0,
    counters: [],
    selectedPriority: "All",
    selectedCounter: "All",
    priorityCounter: {
      All: {
        High: 0,
        Low: 0,
        Medium: 0,
      },
      Resolved: {
        High: 0,
        Low: 0,
        Medium: 0,
      },
      "Under Monitoring": {
        High: 0,
        Low: 0,
        Medium: 1,
      },
      Unresolved: {
        High: 0,
        Low: 0,
        Medium: 0,
      },
    },
    action: "",
    result: "",
    action2: "",
    result2: "",
    action3: "",
    result3: "",
    remark: "",
    selectedTrip: null,
    selectedTripId: null,
    subtag: "",
    actionDispatched: null,
    selectedTags: [],
    reportURL: null,
    loading: true,
    firstLoad: true,
    analytics: {},
    statusChangeTrip: {},
  });
  const [tableHeaders, setTableHeaders] = useState([]);
  const [config, setConfig] = useState({});
  const [status, setStatus] = useState(null);
  const clientForMovementType = ["FKT", "FKT-Large"];
  const clientForZone = ["FKT-Large"];

  const [loader, setLoader] = useState(false);

  useEffect(() => {
    axios
      .get(
        "https://da93wz82kc.execute-api.ap-south-1.amazonaws.com/api/v1/auth/verify?config=true",
        {
          headers: {
            Authorization: `Bearer ${props.token}`,
          },
        }
      )
      .then((res) => {
        const headers =
          res.data?.result[0]?.config?.table_headers ?? table_header;
        setConfig(res.data?.result[0]);
        headers.forEach((obj) => {
          obj.width = obj.width ? obj.width : 100 / headers.length + "%";
        });
        setTableHeaders(headers);
        setState({
          ...state,
          actual_headers: headers.reduce((a, b) => {
            a[b.header] = true;
            return a;
          }, {}),
        });
      })
      .catch(() => setTableHeaders(table_header));
  }, []);

  const second_api = axios.create({
    // baseURL: 'https://xi3w8anfm4.execute-api.ap-south-1.amazonaws.com/api/v2/',
    // baseURL: 'https://cenbo4gyw4.execute-api.ap-south-1.amazonaws.com/api/v1/',
    baseURL: "https://da93wz82kc.execute-api.ap-south-1.amazonaws.com/api/v1",
    // baseURL: 'https://6676-103-133-121-6.ngrok.io/api/v1',
    headers: {
      Authorization: `Bearer ${props.token}`,
    },
  });
  const classes = useStyles();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const interval = React.useRef();
  const [hitApply, setHitApply] = useState(false);

  React.useEffect(
    (state) => {
      if (props.logged_in) {
        initComponentData();
        if (["H&S Supply Chain Solution"].includes(props.client)) {
          interval.current = setInterval(initComponentData, 2000 * 60);
        }
      } else {
        props.history.push("/");
      }
      return () => {
        clearInterval(interval.current);
      };
    },
    [page, rowsPerPage, search, hitApply]
  ); // eslint-disable-line react-hooks/exhaustive-deps

  const callActions = ["Called Driver", "Pre Closed"];
  const CallResult = [
    "Call Picked",
    "Switched off",
    "Didnt pick the call",
    "Driver Change",
    "Wrong Number",
    "Language Problem",
  ];
  const callActions2 = ["Called New Driver", "Called Transporter"];
  const CallResult2 = [
    "Driver picked",
    "Driver didn't pick the call",
    "Transporter Picked",
    "Transporter Picked and Gave Number",
    "Transporter Picked and Said vehicle reached",
    "Transporter Picked and Confirmed",
    "Transporter didn't picked the call",
  ];
  const callActions3 = ["Called Transporter"];
  const CallResult3 = [
    "Transporter Picked",
    "Transporter Picked and Gave Number",
    "Transporter Picked and Said vehicle reached",
    "Transporter Picked and Confirmed",
    "Transporter didn't picked the call",
  ];

  function changePriority(value) {
    let priority = state.selectedPriority === value ? "All" : value;
    setState(
      (state) => ({
        ...state,
        selectedPriority: priority,
      }),
      filterRows(state.selectedCounter, pureRows, priority)
    );
  }

  // function downloadExcel() {

  //     second_api({
  //         url: 'trips/reports',
  //         method: 'GET'
  //     }).then(res => {
  //         console.log(res)
  //         const wb = XLSX.utils.book_new();
  //         const file_name = `Control Tower - ${moment().format('DD-MM-YYYY')}.xlsx`;
  //         const ctr = res.data.result.ct_report.map(data => {
  //             const obj = {};
  //             const issues_copy = [...data.issues];
  //             ct_report_headers.forEach(header => {
  //                 if (header.calculate === 'driver_phone') obj[header.text] = data.tel[0]
  //                 else if (header.calculate === 'delay_hrs') obj[header.text] = (issues_copy.reverse().find(issue => issue.issue === "Delay") || {}).value
  //                 else if (header.calculate === 'halt_hrs') obj[header.text] = (issues_copy.reverse().find(issue => issue.issue === "Halt") || {}).value
  //                 else if (header.calculate === 'reasons') obj[header.text] = data.action && data.action.length ? data.action[data.action.length-1].subtag : 'NA'
  //                 else if (header.calculate === 'reason for delay') obj[header.text] = data.action && data.action.length ? data.action[data.action.length-1].remark : 'NA'
  //                 else if (header.calculate === 'last_known_loc') obj[header.text] = data.last_known_loc && data.last_known_loc.join(", ")
  //                 else obj[header.text] = data[header.value]
  //             })
  //             return obj;
  //         })
  //         XLSX.utils.book_append_sheet(
  //             wb, XLSX.utils.json_to_sheet(ctr),
  //             "Control Tower Report"
  //         );
  //         const trt = res.data.result.running_trips.map(data => {
  //             const obj = {};
  //             total_trips_report_headers.forEach(header => {
  //                 if (header.calculate === 'driver_phone') obj[header.text] = data.tel[0]
  //                 else obj[header.text] = data[header.value]
  //             })
  //             return obj;
  //         })
  //         XLSX.utils.book_append_sheet(
  //             wb, XLSX.utils.json_to_sheet(trt),
  //             "Total Running Trips"
  //         );
  //         XLSX.writeFile(wb, file_name);
  //     }).catch(err => {
  //         console.log(err)
  //     })
  // }

  // function handleSearch(e) {
  //   let value = e.target.value;
  //   setSearch((s) => value);
  // }

  // Commented out to resolve filter issue
  React.useEffect(
    (_) => {
      //   filterRows(state.selectedCounter, pureRows, state.selectedPriority);
    },
    [pureRows]
  ); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const downloadReport = () => {
    setLoader(true);
    let url = "reports";
    let count = 0;
    if (state.report?.startDate) {
      count++;
      url += `?starttime=${startOfDay(
        new Date(state.report?.startDate)
      ).valueOf()}`;
    }
    if (state.report?.endDate) {
      count++;
      url += `${count > 1 ? "&" : "?"}endtime=${endOfDay(
        new Date(state.report?.endDate)
      ).valueOf()}`;
    }
    second_api({
      url: url,
      method: "GET",
    })
      .then((r) => {
        const link = document.createElement("a");
        link.href = r.data.result;
        link.download = "Report";
        link.click();
        // window.open(r.data.result);

        // const book = XLSX.utils.book_new();
        // XLSX.utils.book_append_sheet(
        //   book,
        //   XLSX.utils.json_to_sheet(r.data.result),
        //   "Data"
        // );
        // XLSX.writeFile(book, "Control Tower Report.xlsx");
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => setLoader(false));
  };

  const [showDialog, setShowDialog] = React.useState({
    displayItems: false,
    actionsResult: false,
    analysis: false,
    // dateFilter: false
  });

  const toggleDialog = (dialog, show) => {
    setShowDialog((showDialog) => ({
      ...showDialog,
      [dialog]: show,
    }));
  };

  function dispatchAction(e, trip, action) {
    e.stopPropagation();
    setStatus(e.target.value);
    setState((state) => ({
      ...state,
      actionDispatched: action,
      selectedTripId: trip.tripId,
      statusChangeTrip: trip,
    }));
    toggleDialog("actionsResult", true);
  }

  function addAction() {
    let {
      selectedTripId,
      action,
      result,
      subtag,
      action2,
      result2,
      subtag2,
      remark,
      action3,
      result3,
    } = state;

    let data = {
      tripId: selectedTripId,
      action: {
        action,
        result,
        remark,
        subtag,
        action2,
        result2,
        action3,
        result3,
      },
    };
    if (
      state.actionDispatched === "statusChange" &&
      config?.config?.updateStatusPopUp
    )
      data.status = status;

    second_api({
      url: "trips",
      method: "PATCH",
      data: data,
    })
      .then((res) => {
        setPureRows((rows) =>
          rows.map((row) => {
            let newRow = { ...row };
            if (newRow.tripId === selectedTripId) {
              row.timeline = row.timeline || [];
              row.timeline.unshift({
                action,
                result,
                tripId: selectedTripId,
                subtag,
                createdAt: new Date().toDateString(),
                issue_tag: row.issues[0]?.issue_tag,
              });
            }
            return newRow;
          })
        );
        setState((state) => ({
          ...state,
          actionDispatched: null,
          selectedTrip: null,
          action: "",
          result: "",
          subtag: "",
          action2: "",
          result2: "",
          action3: "",
          result3: "",
        }));
        toggleDialog("actionsResult", false);
        updateRowData(
          state.statusChangeTrip,
          status,
          state.statusChangeTrip.status
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const clearAllHeaders = () => {
    let headers = { ...state[state.actualHeaderVariable] };
    Object.keys(headers).forEach((key) => {
      if (!defaultCheckedColumn.includes(key)) headers[key] = false;
    });
    setState((state) => ({
      ...state,
      [state.actualHeaderVariable]: headers,
    }));
  };

  const selectAllHeaders = () => {
    let headers = { ...state[state.actualHeaderVariable] };
    Object.keys(headers).forEach((key) => {
      if (!defaultCheckedColumn.includes(key)) headers[key] = true;
    });
    setState((state) => ({
      ...state,
      [state.actualHeaderVariable]: headers,
    }));
  };

  const changeHandler = (e, key, prop) => {
    console.log(e);
    let value = e.target.value;
    if (key) {
      setState((state) => ({
        ...state,
        [key]: {
          ...state[key],
          [prop]: value,
        },
      }));
    } else {
      setState((state) => ({
        ...state,
        [prop]: value,
      }));
    }
  };

  const handleActionChange = (e, prop) => {
    let value = e.target.value;
    const hasAction2 = [
      "Switched off",
      "Didnt pick the call",
      "Driver Change",
      "Wrong Number",
      "Language Problem",
    ];
    const hasAction3 = ["Driver didn't pick the call"];
    const result1 = prop === "result" ? value : state.result;
    const result2 =
      prop === "result2"
        ? value
        : hasAction2.includes(result1)
        ? state.result2
        : "";
    setState((state) => ({
      ...state,
      [prop]: value,
      action2:
        prop === "action2"
          ? value
          : hasAction2.includes(result1)
          ? state.action2
          : "",
      result2,
      action3:
        prop === "action3"
          ? value
          : hasAction2.includes(result1) && hasAction3.includes(result2)
          ? state.action3
          : "",
      result3:
        prop === "result3"
          ? value
          : hasAction2.includes(result1) && hasAction3.includes(result2)
          ? state.result3
          : "",
    }));
  };

  const [timelineData, setTimelineData] = useState([]);

  const toggleMap = (row) => {
    let value = (state.selectedTrip || {}).tripId === row.tripId ? null : row;
    changeHandler({ target: { value: value } }, null, "selectedTrip");

    axios
      .get(
        `https://da93wz82kc.execute-api.ap-south-1.amazonaws.com/api/v1/trips/timeline?tripId=${row?.tripId}`,
        {
          headers: {
            Authorization: `Bearer ${props.token}`,
          },
        }
      )
      .then((res) => setTimelineData(res.data?.result));
  };

  function initComponentData(
    reportLoad = false,
    filterCounter,
    priority,
    selectedTags
  ) {
    setState((state) => ({
      ...state,
      // refreshLoading: true,
      loading: true,
    }));
    // slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

    const promises = [
      second_api({
        // url: `trips?limit=${rowsPerPage}&offset=${page}`,
        // method: "GET",
        url: `trips?limit=${rowsPerPage}&offset=${page}`,
        method: "POST",
        data: {
          filters: {
            priority: priority || state.selectedPriority,
            issue_tag: selectedTags || state.selectedTags,
            status: filterCounter || state.selectedCounter,
            src: filterData.Source ?? undefined,
            dest: filterData.Destination ?? undefined,
            transporter: filterData.Transporter ?? undefined,
            trip_status: filterData["Trip Status"] ?? undefined,
            movement_type: filterData["Movement Type"] ?? undefined,
            zone: filterData.Zone ?? undefined,
            city: filterData.City ?? undefined,
            client_name: filterData["Client Name"] ?? undefined,
          },
          search_text: search ? search : undefined,
        },
      }),
    ];
    if (
      !["PANDO_CT"].includes(props.client) &&
      (state.firstLoad || reportLoad)
    ) {
      promises.push(
        second_api({
          url: `trips/report`,
          method: "GET",
        })
      );
    }
    Promise.all(promises)
      .then(([res, res2]) => {
        const trips = res.data.result[0].trips;
        trips.forEach((trip) => {
          trip.timeline = [];
          trip.issues.forEach((issue) => {
            issue.actions.forEach((action) => {
              action.issue_tag = issue.issue_tag;
            });
            trip.timeline.push(...issue.actions);
          });
        });
        setPureRows((rows) => trips);
        // Added new line to resolve filter issue
        setRows((_) => trips);

        // Coomented out to resolve filter issue
        // filterRows(state.selectedCounter, trips, state.selectedPriority);
        let tags = [
          ...new Set(res.data.result[0].trips.map((r) => r.issue_tag)),
        ];
        setState((state) => ({
          ...state,
          tags,
          analytics: res.data.result[0].analytics,
          counters: res.data.result[0].mainCounter,
          issuesCounter: res.data.result[0].issueCounter,
          priorityCounter: res.data.result[0].priorityCounter,
          totalTrips: res.data.result[0].totalTrips,
          refreshLoading: false,
          reportURL: res2?.data?.result?.[0]?.file,
          loading: false,
          firstLoad: false,
        }));
      })
      .catch((err) => {
        console.log(err);
        setState((state) => ({
          ...state,
          refreshLoading: false,
        }));
      });
  }

  function counterChanged(filter) {
    setState(
      (state) => ({
        ...state,
        selectedCounter: filter,
      }),
      filterRows(filter)
    );
  }

  function filterRows(
    filterCounter = state.selectedCounter,
    rows = pureRows,
    priority = state.selectedPriority,
    selectedTags = state.selectedTags
  ) {
    setPage(0);

    initComponentData(false, filterCounter, priority, selectedTags);

    // OLD Used to filter from UI

    // setRows((_) => []);
    // priority = priority.toUpperCase();
    // let newRows = rows.filter((row) => {
    //   if (priority === "ALL" && filter === "All") return true;
    //   if (priority === "ALL" && filter === row.status) return true;
    //   if (filter === "All" && priority === row.priority) return true;
    //   if (filter === row.status && priority === row.priority) return true;
    //   return false;
    // });
    // if (selectedTags.length) {
    //   newRows = newRows.filter((row) =>
    //     row.issue_tag.includes(selectedTags.join(" + "))
    //   );
    // }
    // setRows((_) => newRows);
  }

  function updateRowData(row, value, prev_status) {
    let rows = pureRows;
    rows = rows.map((prow) => {
      let newProw = { ...prow };
      if (row.tripId === newProw.tripId) newProw.status = value;
      return newProw;
    });
    let counters = state.counters;
    counters = counters.map((counter) => {
      let newCounter = { ...counter };
      if (newCounter.text === prev_status) newCounter.count--;
      if (newCounter.text === value) newCounter.count++;
      return newCounter;
    });
    setState((state) => ({
      ...state,
      counters,
    }));
    setPureRows((_) => rows);
    filterRows(
      state.selectedCounter,
      rows,
      state.selectedPriority,
      state.selectedTags
    );
  }
  function updateMainStatus(e, row) {
    e.stopPropagation();
    let event = e;
    let value = e.target.value;
    let prev_status = row.status;

    if (!config?.config?.updateStatusPopUp)
      second_api({
        url: "trips",
        method: "PATCH",
        data: {
          tripId: row.tripId,
          status: value,
        },
      })
        .then((res) => {
          updateRowData(row, value, prev_status);
        })
        .catch((err) => {
          console.log(err);
        });
    else {
      dispatchAction(event, row, "statusChange");
    }
  }

  function getPriorityColor(priority) {
    if (state.selectedPriority === priority) return "white";
    if (priority === "High") return "#FE5858";
    if (priority === "Medium") return "#EDBD00";
    if (priority === "Low") return "#009CB5";
  }

  function getPriorityBGColor(priority) {
    if (state.selectedPriority !== priority) return "transparent";
    if (priority === "High") return "#FE5858";
    if (priority === "Low") return "#009CB5";
    if (priority === "Medium") return "#EDBD00";
  }

  // function handleDateChange(date) {
  //     console.log(date)
  //     setState(state => ({
  //         ...state,
  //         date: date.selection
  //     }))
  // }

  function handleTagClick(tag) {
    let newSelectedTags =
      tag === "All"
        ? []
        : state.selectedTags.includes(tag)
        ? state.selectedTags.filter((stag) => stag !== tag)
        : [...state.selectedTags, tag];
    setState(
      (state) => ({
        ...state,
        selectedTags: newSelectedTags,
      }),
      filterRows(
        state.selectedCounter,
        pureRows,
        state.selectedPriority,
        newSelectedTags
      )
    );
  }

  const [issueData, setIssueData] = useState([]);
  // useEffect(() => {
  //   second_api({
  //     url: "trips/analytics",
  //     method: "GET",
  //   })
  //     .then((res) => {
  //       console.log(res);
  //       setIssueData(res.data);
  //     })
  //     .catch((err) => console.log(err));
  // }, []);

  const callAnalyticsApi = () => {
    second_api({
      url: "trips/analytics",
      method: "GET",
    })
      .then((res) => {
        console.log(res);
        setIssueData(res.data);
      })
      .catch((err) => console.log(err));
  };

  // const MINUTE_MS = 3000;
  const MINUTE_MS = 1000 * 60 * 10;

  // While the user in on Analysis Page call every MINUTE_MS ms
  useEffect(() => {
    if (showDialog.analysis && config?.config?.newAnalytics) {
      const interval = setInterval(() => {
        second_api({
          url: "trips/analytics",
          method: "GET",
        })
          .then((res) => {
            console.log(res);
            setIssueData(res.data);
          })
          .catch((err) => console.log(err));
      }, MINUTE_MS);

      return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }
  }, [showDialog.analysis, config?.config?.newAnalytics]);

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("transporter");

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const refreshApp = () => {
    if (state.refreshLoading) return;
    setState((state) => ({
      ...state,
      refreshLoading: true,
    }));
    second_api({
      url: "trips/reload",
      method: "GET",
    })
      .then((res) => {
        setTimeout(() => {
          setRows((rows) => []);
          initComponentData(true);
        }, 10000);
      })
      .catch((err) => {
        console.log(err);
        setState((state) => ({
          ...state,
          refreshLoading: false,
        }));
      });
  };

  useEffect(() => {
    console.log(state[state.actualHeaderVariable]);
  }, [state[state.actualHeaderVariable]]);

  useEffect(() => {
    console.log(tableHeaders);
  }, [tableHeaders]);

  useEffect(() => {
    console.log(rows);
  }, [rows]);
  return (
    <Grid container>
      {/* {showDialog.dateFilter ? 
            <Grid item className={classes.dateContainer}> 
                <Grid container direction="column">
                    <DateRangePicker
                        showMonthArrow
                        ranges={[state.date]}
                        onChange={(date) => handleDateChange(date)}
                    /> 
                    <Grid item>
                        <Grid container justify="flex-end">
                            <Button onClick={() => toggleDialog('dateFilter', false)}>Cancel</Button>
                            <Button onClick={() => toggleDialog('dateFilter', false)}>Submit</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>: null} */}
      <Grid item xs={12}>
        <Counters onCounterClick={counterChanged} counters={state.counters} />
      </Grid>
      <Grid item xs={12}>
        <Grid
          className={classes.homeHeading}
          container
          justify="center"
          alignItems="center"
        >
          <Grid item style={{ margin: "5px 15px" }}>
            <Grid container direction="column" alignItems="center">
              <Grid item>
                {Object.keys(state.priorityCounter[state.selectedCounter]).map(
                  (counter) => (
                    <Button
                      variant="outlined"
                      onClick={() => changePriority(counter)}
                      className={classes[counter]}
                      value={
                        state.priorityCounter[state.selectedCounter][counter]
                      }
                      style={{
                        backgroundColor: getPriorityBGColor(counter),
                        color: getPriorityColor(counter),
                      }}
                    >
                      {counter}
                    </Button>
                  )
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item style={{ margin: "5px 15px", width: "400px" }}>
            <TextField
              fullWidth
              variant="filled"
              InputProps={{
                disableUnderline: true,
                style: { background: "white" },
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              className={classes.formelement}
              id="input-with-icon-grid"
              label="Search Trips"
              // onChange={handleSearch}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Grid>
          <VisibilityIcon
            color="primary"
            className={classes.icons}
            onClick={() => toggleDialog("displayItems", true)}
          ></VisibilityIcon>
          {
            // ["H&S Supply Chain Solution"].includes(props.client)
            config?.config?.reportType === "timeframe" ? (
              <CloudDownloadIcon
                color="primary"
                className={classes.icons}
                onClick={(e) =>
                  changeHandler({ target: { value: true } }, "report", "dialog")
                }
              />
            ) : !state.loading && !["PANDO_CT"].includes(props.client) ? (
              <a href={state.reportURL} download>
                <CloudDownloadIcon color="primary" className={classes.icons} />
              </a>
            ) : null
          }

          <BarChartIcon
            color="primary"
            className={classes.icons}
            onClick={() => {
              toggleDialog("analysis", true);
              config?.config?.newAnalytics && callAnalyticsApi();
            }}
          />
          {/* <DateRangeIcon color="primary" className={classes.icons} onClick={() => toggleDialog('dateFilter', true)} /> */}
          <div className={classes.loaderWrapper + " " + classes.icons}>
            <RefreshIcon
              color="primary"
              disabled={props.clientData.write === "disabled"}
              onClick={refreshApp}
            />
            {state.refreshLoading && (
              <CircularProgress size={20} className={classes.loader} />
            )}
          </div>
          {/* <FilterListIcon color="primary" className={classes.icons} /> */}
          <Filter
            content={
              <List
                style={{ paddingLeft: "25px", height: "calc(100% - 112px)" }}
              >
                {[
                  "Source",
                  "Destination",
                  "Transporter",
                  "Trip Status",
                  "Movement Type",
                  "Zone",
                  "City",
                  "Client Name",
                ]
                  ?.filter((item) =>
                    item === "Trip Status"
                      ? clientForMovementType?.includes(config?.client)
                      : true
                  )
                  //only allowing movement types for ["FKT", "FKT Large"]
                  ?.filter((item) =>
                    item === "Movement Type"
                      ? clientForMovementType?.includes(config?.client)
                      : true
                  )
                  ?.filter((item) =>
                    item === "Zone"
                      ? clientForZone?.includes(config?.client)
                      : true
                  )
                  ?.filter((item) =>
                    item === "City" ? config?.client === "Lets Transport" : true
                  )
                  ?.filter((item) =>
                    item === "Client Name"
                      ? config?.client === "Lets Transport"
                      : true
                  )
                  ?.map((label) => (
                    <>
                      {/* <InputLabel
                        style={{ margin: "20px 0px" }}
                        id="demo-simple-select-label"
                      >
                        {label}
                      </InputLabel> */}
                      <Autocomplete
                        style={{ width: "200px" }}
                        labelId="demo-mutiple-name-label"
                        id="demo-mutiple-name"
                        multiple
                        key={filterData[label]}
                        value={filterData[label] ?? []}
                        onChange={(e, values) => {
                          setFilterData({
                            ...filterData,
                            [label]: values,
                          });
                        }}
                        fullWidth
                        options={
                          getFilters?.[changeKeys[label]]
                            ?.filter((option) => option)
                            ?.map((option) => String(option)) ?? []
                        }
                        getOptionLabel={(option) => option}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={label}
                            margin="normal"
                          />
                        )}
                      />
                      {/* <Select
                        style={{ width: "200px" }}
                        labelId="demo-mutiple-name-label"
                        id="demo-mutiple-name"
                        multiple
                        key={filterData[label]}
                        value={filterData[label] ?? []}
                        onChange={(e) => {
                          setFilterData({
                            ...filterData,
                            [label]: e.target.value,
                          });
                        }}
                        fullWidth
                      >
                        {getFilters?.[changeKeys[label]]?.map((item) => (
                          <MenuItem value={item}>{item}</MenuItem>
                        ))}
                      </Select> */}
                    </>
                  ))}
              </List>
            }
            actionButton={
              <>
                <Button onClick={handleClearFilter}>Cancel</Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleApplyFilter}
                >
                  Apply
                </Button>
              </>
            }
          />
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ margin: "5px" }}>
        <Grid container justify="center">
          <Grid item style={{ margin: "5px 15px" }}>
            <Grid container direction="row" alignItems="center">
              {/* <Grid item className={classes.optionsHeading}>
                                Filter by Tag
                            </Grid> */}
            </Grid>
          </Grid>
          <ButtonGroup
            variant="text"
            color="primary"
            aria-label="text primary button group"
          >
            {state.issuesCounter.map((issue) => (
              <Button
                style={{
                  backgroundColor:
                    (state.selectedTags.length === 0 && issue.text === "All") ||
                    state.selectedTags.includes(issue.text)
                      ? "#447ad2"
                      : "transparent",
                  color:
                    (state.selectedTags.length === 0 && issue.text === "All") ||
                    state.selectedTags.includes(issue.text)
                      ? "white"
                      : "#447ad2",
                }}
                className={classes.issueCountButton}
                onClick={() => handleTagClick(issue.text)}
              >
                {issue.text}{" "}
                <span style={{ color: "black", marginLeft: "10px" }}>
                  {issue.count}
                </span>
              </Button>
            ))}
          </ButtonGroup>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.root}>
          <TableContainer className={classes.tableContainer}>
            {state.loading && <LinearProgress />}
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {/* {table_header.map((column) => */}
                  {tableHeaders.map((column) =>
                    state[state.actualHeaderVariable][column.header] ? (
                      <TableCell
                        key={column.header}
                        align="center"
                        width={column.width}
                        className={classes.tableCellHeader}
                      >
                        {column.sorting ? (
                          <TableSortLabel
                            active={orderBy === column.header}
                            direction={
                              orderBy === column.header ? order : "asc"
                            }
                            onClick={() => handleRequestSort(column.key)}
                          >
                            {column.header}
                          </TableSortLabel>
                        ) : (
                          column.header
                        )}
                      </TableCell>
                    ) : null
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {stableSort(
                  rows.filter((row) =>
                    Object.keys(row).some((key) =>
                      row[key]
                        ? row[key]
                            .toString()
                            .toUpperCase()
                            .includes(search.toUpperCase())
                        : false
                    )
                  ),
                  // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
                  getComparator(order, orderBy)
                ).map((row, index) => {
                  return (
                    <React.Fragment key={row._id + row.issue}>
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        className={classes.tableRow}
                        onClick={(e) => toggleMap(row)}
                      >
                        {state[state.actualHeaderVariable]["Status"] ? (
                          <TableCell
                            className={classes.tableCell}
                            style={{
                              borderLeft: `15px solid ${
                                row.priority === "HIGH"
                                  ? "#FE5858"
                                  : row.priority === "LOW"
                                  ? "#009CB5"
                                  : "#EDBD00"
                              }`,
                              padding: "0px 0px 0px 15px",
                            }}
                          >
                            <TextField
                              style={{ width: "125px", margin: "5px" }}
                              InputProps={{
                                disableUnderline: true,
                                style: {
                                  background: "#F4EFFF",
                                  color: "#6332CE",
                                },
                              }}
                              variant="filled"
                              select
                              value={row.status}
                              disabled={props.clientData.write === "disabled"}
                              onChange={(e) => updateMainStatus(e, row)}
                              className={classes.tripStatusField}
                            >
                              <MenuItem value={"Resolved"}>Resolved</MenuItem>
                              <MenuItem value={"Unresolved"}>
                                Unresolved
                              </MenuItem>
                              <MenuItem value={"Under Monitoring"}>
                                Under Monitoring
                              </MenuItem>
                              {[].map((tag, index) => (
                                <MenuItem key={index} value={tag}>
                                  {tag}
                                </MenuItem>
                              ))}
                            </TextField>
                          </TableCell>
                        ) : null}
                        {state[state.actualHeaderVariable]["Actions"] ? (
                          <TableCell
                            align="center"
                            className={classes.tableCell}
                            style={{ minWidth: "140px" }}
                          >
                            <Grid
                              container
                              alignItems="center"
                              justify="center"
                            >
                              <Grid item>
                                <Tooltip title="Called">
                                  <IconButton
                                    aria-label="Called"
                                    onClick={(e) =>
                                      dispatchAction(e, row, "call")
                                    }
                                    style={{
                                      backgroundColor: "#C5FFCB",
                                      borderRadius: "0",
                                      padding: "6px",
                                      marginRight: "5px",
                                    }}
                                  >
                                    <PhoneIcon
                                      style={{
                                        color: "#28AE14",
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                              <Grid item>
                                <Tooltip title="Sent a mail">
                                  <IconButton
                                    aria-label="Sent a mail"
                                    onClick={(e) =>
                                      dispatchAction(e, row, "email")
                                    }
                                    style={{
                                      backgroundColor: "#C5FFCB",
                                      borderRadius: "0",
                                      padding: "6px",
                                      marginLeft: "5px",
                                    }}
                                  >
                                    <EmailIcon
                                      style={{
                                        color: "#28AE14",
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </TableCell>
                        ) : null}
                        {
                          // (
                          //   props.client === "INTUGINE OPS"
                          //   ? ops_table_header
                          //   : table_header
                          // )
                          tableHeaders.map((column) => {
                            return column.data &&
                              state[state.actualHeaderVariable][
                                column.header
                              ] ? (
                              <TableCell
                                className={classes.tableCell}
                                key={column.header}
                                align="center"
                              >
                                {column.key === "startTime" ||
                                column.key === "src_out_time" ? (
                                  formatDate(row[column.key])
                                ) : (row[column.key] + "").length > 35 ? (
                                  <Tooltip title={row[column.key]}>
                                    <span>
                                      {(row[column.key] + "").slice(0, 35) +
                                        "..."}
                                    </span>
                                  </Tooltip>
                                ) : (
                                  row[column.key]
                                )}
                              </TableCell>
                            ) : null;
                          })
                        }
                        {state[state.actualHeaderVariable]["Test Field"] ? (
                          <TableCell
                            align="center"
                            className={classes.tableCell}
                          >
                            {row.delay
                              ? row.delay > 3600
                                ? `Delayed by ${Math.trunc(
                                    row.delay / 3600
                                  )} hrs`
                                : `Delayed by ${Math.trunc(
                                    row.delay / 60
                                  )} mins`
                              : ""}
                            {row.delay && row.halts ? " and " : ""}
                            {row.halts
                              ? row.halts > 3600
                                ? `Halted for ${Math.trunc(
                                    row.halts / 3600
                                  )} hrs`
                                : `Halted for ${Math.trunc(
                                    row.halts / 60
                                  )} mins`
                              : ""}
                          </TableCell>
                        ) : null}

                        {state[state.actualHeaderVariable]["Duration"] ? (
                          <TableCell
                            align="center"
                            className={classes.tableCell}
                          >
                            {row.issue_value}
                            {/* {row.issues[0].value ? row.issues[0].value >= 24 ? `${Math.trunc(row.issues[0].value/24)} day` : `${Math.trunc(row.issues[0].value)} hr` : 'NA'} */}
                          </TableCell>
                        ) : null}
                        {state[state.actualHeaderVariable][
                          "Last Known Address"
                        ] ? (
                          <TableCell
                            align="center"
                            className={classes.tableCell}
                          >
                            {/* test */}
                            {row?.last_known_address}
                          </TableCell>
                        ) : null}

                        {state[state.actualHeaderVariable]["City Name"] ? (
                          <TableCell
                            align="center"
                            className={classes.tableCell}
                          >
                            {/* test */}
                            {row?.city}
                          </TableCell>
                        ) : null}

                        {state[state.actualHeaderVariable]["Client Name"] ? (
                          <TableCell
                            align="center"
                            className={classes.tableCell}
                          >
                            {/* test */}
                            {row?.client_name}
                          </TableCell>
                        ) : null}

                        {/* {state[state.actualHeaderVariable]["Client Name"] ? (
                          <TableCell
                            align="center"
                            className={classes.tableCell}
                          ></TableCell>
                        ) : null} */}
                        {/* {state[state.actualHeaderVariable]['Action 1'] ? 
                                    </TableRow> <TableCell align="center" style={{
                                        borderBottom: '5px solid #f7f7f7',
                                        color: '#43425d',
                                        padding: '5px'
                                    }}>
                                        <FormControl className={classes.formControl}>
                                            <InputLabel id="demo-simple-select-helper-label">Action 1</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                // value={row.tag}
                                                onChange={(e) => {
                                                    changeCTR(e, 'action1', index)
                                                }}
                                                value={row.action1 && row.ctr.action1.text ? row.ctr.action1.text : ''}
                                                >
                                                {action1.map(action => (
                                                    <MenuItem key={action} value={action}>{action}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </TableCell> : null}*/}
                        {state[state.actualHeaderVariable]["Latest Remark"] ? (
                          <TableCell
                            align="center"
                            className={classes.tableCell}
                          >
                            <Grid container direction="column">
                              <span> {row?.status || "NA"} </span>
                              <Tooltip
                                title={
                                  row?.timeline[0]?.remark ||
                                  row?.timeline[0]?.subtag ||
                                  "NA"
                                }
                              >
                                <span
                                  style={{
                                    color: "gray",
                                    fontSize: "10px",
                                    margin: "5px",
                                    maxWidth: "70%",
                                    maxHeight: "30px",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    alignSelf: "center",
                                  }}
                                >
                                  {row?.timeline[0]?.remark ||
                                    row?.timeline[0]?.subtag ||
                                    "NA"}
                                </span>
                              </Tooltip>
                            </Grid>
                          </TableCell>
                        ) : null}
                      </TableRow>
                      {state.selectedTrip &&
                      state.selectedTrip.tripId === row.tripId ? (
                        <TableRow>
                          <TableCell
                            colSpan={Object.keys(
                              state.actualHeaderVariable
                            ).reduce((count, key) => {
                              return state.actualHeaderVariable[key]
                                ? count + 1
                                : count;
                            }, 0)}
                          >
                            <Grid container>
                              <Grid item xs={7}>
                                <iframe
                                  src={state.selectedTrip.public_link}
                                  title={state.selectedTrip.tripId}
                                  style={{ height: "550px", width: "100%" }}
                                ></iframe>
                              </Grid>
                              <Grid item xs={5}>
                                <Grid
                                  container
                                  justify="center"
                                  direction="column"
                                  style={{ padding: "5px 30px" }}
                                >
                                  <Typography variant="h6">Remarks</Typography>
                                  <Timeline
                                    style={{
                                      height: "450px",
                                      overflowY: "auto",
                                    }}
                                  >
                                    {/* {(row.timeline || []).map((action) => ( */}
                                    {(timelineData || []).map((action) => (
                                      <TimelineItem>
                                        <TimelineOppositeContent>
                                          <div>
                                            <p align="center">
                                              {action?.issue_tag ?? ""}
                                            </p>
                                            <p
                                              align="center"
                                              style={{
                                                color: "rgba(0, 0, 0, 0.54)",
                                              }}
                                            >
                                              {moment(
                                                action?.issue_time
                                              ).format(
                                                // "YYYY-MM-DD HH:mm"
                                                "DD-MM-YYYY HH:mm"
                                              ) ?? ""}
                                            </p>
                                          </div>
                                        </TimelineOppositeContent>
                                        <TimelineSeparator>
                                          <TimelineDot
                                            color="primary"
                                            variant="outlined"
                                          />
                                          <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent
                                          style={{ fontSize: "15px" }}
                                        >
                                          {action?.subtag ?? ""}
                                          <p
                                            // align="center"
                                            style={{
                                              display: "flex",
                                              marginTop: "12px",
                                              fontSize: "14px",
                                              color: "rgba(0, 0, 0, 0.54)",
                                            }}
                                          >
                                            {action?.subtag
                                              ? moment(
                                                  action?.createdAt
                                                ).format(
                                                  // "YYYY-MM-DD HH:mm"
                                                  "DD-MM-YYYY HH:mm"
                                                )
                                              : ""}
                                          </p>
                                        </TimelineContent>
                                      </TimelineItem>
                                    ))}
                                  </Timeline>
                                </Grid>
                              </Grid>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      ) : null}
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={state.totalTrips}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </Grid>
      <Dialog
        open={state.report?.dialog}
        onClose={() =>
          changeHandler({ target: { value: false } }, "report", "dialog")
        }
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Download Report</DialogTitle>

        <DialogContent style={{ position: "relative" }}>
          {loader && (
            <div
              style={{
                position: "absolute",
                top: "calc(50% - 20px)",
                left: "calc(50% - 20px)",
              }}
            >
              <CircularProgress />
            </div>
          )}
          <Grid container justify="space-evenly">
            <Grid item xs={5}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  label="Select Start Date"
                  format="MM/dd/yyyy"
                  value={state.report?.startDate}
                  // onChange={handleDateChange}
                  onChange={(v) =>
                    changeHandler(
                      { target: { value: v } },
                      "report",
                      "startDate"
                    )
                  }
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  maxDate={new Date()}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={5}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  label="Select End Date"
                  format="MM/dd/yyyy"
                  value={state.report?.endDate}
                  onChange={(v) =>
                    changeHandler({ target: { value: v } }, "report", "endDate")
                  }
                  // onChange={handleDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  minDate={state.report?.startDate}
                  maxDate={
                    addDays(state.report?.startDate, 6) > new Date()
                      ? new Date()
                      : addDays(state.report?.startDate, 6)
                  }
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() =>
              changeHandler({ target: { value: false } }, "report", "dialog")
            }
            color="primary"
          >
            Close
          </Button>
          <Button
            onClick={() =>
              setState((s) => ({
                ...state,
                report: { ...state.report, startDate: null, endDate: null },
              }))
            }
            color="primary"
          >
            Clear
          </Button>
          <Button onClick={downloadReport} color="primary" disabled={loader}>
            Download
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showDialog.displayItems}
        onClose={() => toggleDialog("displayItems", false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Set Display Item"}</DialogTitle>
        <DialogContent>
          <Grid container>
            {Object.keys(state[state.actualHeaderVariable]).map((key) => (
              <Grid item xs={6} key={key}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state[state.actualHeaderVariable][key]}
                      onChange={() => {
                        setState((state) => ({
                          ...state,
                          [state.actualHeaderVariable]: {
                            ...state[state.actualHeaderVariable],
                            [key]: !state[state.actualHeaderVariable][key],
                          },
                        }));
                      }}
                      name="checkedB"
                      color="primary"
                      disabled={defaultCheckedColumn.includes(key)}
                    />
                  }
                  label={key}
                />
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={clearAllHeaders} color="primary">
            Clear All
          </Button>
          <Button
            onClick={() => toggleDialog("displayItems", false)}
            color="primary"
          >
            Ok
          </Button>
          <Button onClick={selectAllHeaders} color="primary">
            Select All
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showDialog.actionsResult}
        onClose={() => toggleDialog("actionsResult", false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className={classes.actionsDialog}>
          <Grid container justify="center">
            <Grid item xs={11} style={{ minWidth: "500px" }}>
              <TextField
                variant="filled"
                select
                label="Select Action 1"
                style={{ background: "white", margin: "20px 0px" }}
                fullWidth
                InputProps={{
                  disableUnderline: true,
                  style: { background: "white" },
                }}
                onChange={(e) => handleActionChange(e, "action")}
                value={state.action}
              >
                <MenuItem value="" disabled>
                  Select Action 1
                </MenuItem>
                {callActions.map((tag, index) => (
                  <MenuItem key={index} value={tag}>
                    {tag}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {state.action && state.action !== "Pre Closed" ? (
              <Grid item xs={11}>
                <TextField
                  style={{ margin: "20px 0px" }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      background: "white",
                    },
                  }}
                  onChange={(e) => handleActionChange(e, "result")}
                  value={state.result}
                  variant="filled"
                  select
                  label="Select Result 1"
                  fullWidth
                >
                  <MenuItem value="" disabled>
                    Select Result 1
                  </MenuItem>
                  {CallResult.map((remark, index) => (
                    <MenuItem key={index} value={remark}>
                      {remark}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            ) : null}
            {[
              "Switched off",
              "Didnt pick the call",
              "Driver Change",
              "Wrong Number",
              "Language Problem",
            ].includes(state.result) ? (
              <Grid item xs={11}>
                <TextField
                  variant="filled"
                  select
                  label="Select Action 2"
                  fullWidth
                  style={{ background: "white", margin: "20px 0px" }}
                  InputProps={{
                    disableUnderline: true,
                    style: { background: "white" },
                  }}
                  // onChange={e => changeHandler(e, null, 'action2')}
                  onChange={(e) => handleActionChange(e, "action2")}
                  value={state.action2}
                >
                  <MenuItem value="" disabled>
                    Select Action 2
                  </MenuItem>
                  {callActions2.map((tag, index) => (
                    <MenuItem key={index} value={tag}>
                      {tag}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            ) : null}
            {state.action2 ? (
              <Grid item xs={11}>
                <TextField
                  style={{ margin: "20px 0px" }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      background: "white",
                    },
                  }}
                  // onChange={e => changeHandler(e, null, 'result2')}
                  onChange={(e) => handleActionChange(e, "result2")}
                  value={state.result2}
                  variant="filled"
                  select
                  label="Select result 2"
                  fullWidth
                >
                  <MenuItem value="" disabled>
                    Select result 2
                  </MenuItem>
                  {CallResult2.map((tag, index) => (
                    <MenuItem key={index} value={tag}>
                      {tag}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            ) : null}
            {["Driver didn't pick the call"].includes(state.result2) ? (
              <Grid item xs={11}>
                <TextField
                  style={{ margin: "20px 0px" }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      background: "white",
                    },
                  }}
                  // onChange={e => changeHandler(e, null, 'action3')}
                  onChange={(e) => handleActionChange(e, "action3")}
                  value={state.action3}
                  variant="filled"
                  select
                  label="Select Action 3"
                  fullWidth
                >
                  <MenuItem value="" disabled>
                    Select action 3
                  </MenuItem>
                  {callActions3.map((tag, index) => (
                    <MenuItem key={index} value={tag}>
                      {tag}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            ) : null}
            {state.action3 ? (
              <Grid item xs={11}>
                <TextField
                  style={{ margin: "20px 0px" }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      background: "white",
                    },
                  }}
                  // onChange={e => changeHandler(e, null, 'result3')}
                  onChange={(e) => handleActionChange(e, "result3")}
                  value={state.result3}
                  variant="filled"
                  select
                  label="Select result 3"
                  fullWidth
                >
                  <MenuItem value="" disabled>
                    Select result 3
                  </MenuItem>
                  {CallResult3.map((tag, index) => (
                    <MenuItem key={index} value={tag}>
                      {tag}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            ) : null}
            <Grid item xs={11}>
              <p>Please add a remark regarding your conversation</p>
            </Grid>
            <Grid item xs={11}>
              <TextField
                style={{ margin: "20px 0px" }}
                InputProps={{
                  disableUnderline: true,
                  style: {
                    background: "white",
                  },
                }}
                onChange={(e) => changeHandler(e, null, "subtag")}
                value={state.subtag}
                variant="filled"
                select
                label="Sub Tag"
                fullWidth
              >
                <MenuItem value="" disabled>
                  Select Sub Tag
                </MenuItem>
                {[
                  "",
                  "Client Denied Delivery",
                  "No Entry",
                  "Reached Destination",
                  "Vehicle Breakdown",
                  "Personal Halt",
                  "Monetary Issue",
                  "Festival/Road block",
                  "Wrong route",
                  "Documentation Pending",
                  "Under Monitoring",
                  "Other",
                ].map((tag, index) => (
                  <MenuItem key={index} value={tag}>
                    {tag}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={11}>
              <TextField
                style={{ margin: "20px 0px" }}
                InputProps={{
                  disableUnderline: true,
                  style: {
                    background: "white",
                  },
                }}
                onChange={(e) => changeHandler(e, null, "remark")}
                value={state.remark}
                variant="filled"
                label="Remark"
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className={classes.actionsDialog}>
          <Button
            disabled={
              props.clientData.write === "disabled" ||
              !(
                state.action &&
                (state.action2 ? state.result2 : true) &&
                (state.action3 ? state.result3 : true) &&
                state.selectedTripId
              )
            }
            onClick={addAction}
            color="primary"
            autoFocus
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullScreen
        open={showDialog.analysis}
        onClose={() => toggleDialog("analysis", false)}
      >
        {/* <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={() => toggleDialog('analysis', false)} aria-label="close">
                            <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" className={classes.title}>
                            Analysis
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <div>Test</div> */}
        {config?.config?.newAnalytics ? (
          <AnalysisDialog
            totalTrips={state.totalTrips}
            issueData={issueData}
            close={() => toggleDialog("analysis", false)}
          />
        ) : (
          <Analysis
            totalTrips={state.totalTrips}
            analytics={state.analytics || {}}
            close={() => toggleDialog("analysis", false)}
          />
        )}
        {/*  */}
      </Dialog>
      {/* {visible.remark ? <Remark show={visible.remark} toggleShow={toggleRemark} trip={selectedTrip} index ={selectedIndex} addLocalRemark={addLocalRemark}/> : null} */}
      {/* {visible.status ? <Status show={visible.status} toggleShow={toggleStatus} trip={selectedTrip}/> : null} */}
      {/* {visible.mapview ? <MapView show={visible.mapview} toggleShow={toggleMapView} trip={selectedTrip}/> : null} */}
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  clientData: state.clientData,
  client: state.clientData.client,
  token: state.private,
  logged_in: state.access,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
