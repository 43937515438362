import React from "react";
import Typography from "@material-ui/core/Typography";
import { Breadcrumbs as Breadcrumb } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import ChevronLeft from "../../../icons/ChevronLeft";
import ArrowLeft from "../../../icons/ArrowLeft";
import ChevronRight from "../../../icons/ChevronRight";



const useStyles = makeStyles((theme) => ({
  MuiLinkRoot: {
    color: `${theme.palette.primary.main} !important`,
  },
  root: {
    width: 9,
    height: 9,
  },
  root: {
    fontSize: theme.typography.pxToRem(20),
  },
  fontSizeSmall: {
    fontSize: theme.typography.pxToRem(16),
  },
  fontSizeLarge: {
    fontSize: theme.typography.pxToRem(24),
  },
}));

const Breadcrumbs = ({ links, text }) => {
  const classes = useStyles();
  return (
    <Breadcrumb
      separator={<ChevronRight fontSize="small" color="primary" />}
      aria-label="breadcrumb"
      // className={classes.MuiBreadcrumbsRoot}
    >
      {links.map((item, index) => (
        <Link
          key={index}
          color="primary"
          href={item.url}
          className={classes.MuiLinkRoot}
        >
          {item.name}{" "}
        </Link>
      ))}
      <Typography variant="body1" color="textPrimary">
        {text}
      </Typography>
    </Breadcrumb>
  );
};

export default Breadcrumbs;
