import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "calc(100% + 15px)",
    height: "88px",
    display: "flex",

    boxSizing: "border-box",
    // @ts-ignore
    borderBottom: `1px solid ${theme.palette.primary[200]}`,
    justifyContent: "space-between",
    backgroundColor: theme.palette.primary.contrastText,
  },
}));
const Header = ({ children, style, className }) => {
  const classes = useStyles();
  return (
    <header className={`${classes.root} ${className} `} style={style}>
      {children}
    </header>
  );
};

export default Header;
