import React from 'react';
import { Grid, AppBar, Toolbar, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Chart from "react-apexcharts";


const useStyles = makeStyles(theme => ({
    item: {
        margin: '20px'
    },
    root: {
      width: '100%',
    },
    container: {
      maxHeight: 440,
    },
    title: {
        flexGrow: 1
    }
}))

function Analysis(props) {

    const classes = useStyles();

    const [state, setState] = React.useState({
        table1: {
            page: 0,
            rowsPerPage: 10,
            data: []
        },
        table2: {
            page: 0,
            rowsPerPage: 10,
            headers: [
                { key: 'transporter', text: 'Transporter' },
                { key: 'total_trips', text: 'Total Trips with Issues' },
                { key: 'total_issues', text: 'Total Issues' },
            ],
            data: []
        },
        graph: {
            options: {
                chart: {
                    id: "basic-bar"
                },
                title: {
                    text: "Category wise issues",
                    align: 'center',
                    margin: 10,
                    offsetX: 0,
                    offsetY: 0,
                    floating: false,
                    style: {
                        fontSize:  '18px',
                        fontWeight:  'bold',
                        fontFamily: "'Montserrat', sans-serif !important",
                        color:  '#263238'
                    },
                },
                labels: ["No Data"]
            },
            series: [1],
        }
    })

    const handleChangePage = (event, newPage, table) => {
        setState(state => ({
            ...state,
            [table]: {
                ...state[table],
                page: newPage
            }
        }))
    };

    const handleChangeRowsPerPage = (event, table) => {
        setState(state => ({
            ...state,
            [table]: {
                ...state[table],
                page: 0,
                rowsPerPage: +event.target.value
            }
        }))
    };

    React.useEffect(() => {
        let driver_change = 0;
        let total_issues = 0;
        const table2Data = {};
        const graph = {
            x: [],
            y: [],
        };
        const subtags = props.analytics.category_wise;
        Object.keys(props.analytics.transporter_wise).forEach(k => {
            table2Data[k] = {
                total_issues: props.analytics.transporter_wise[k].issue,
                total_trips: props.analytics.transporter_wise[k].total,
                transporter: k
            }
        })
        total_issues = props.analytics.total_issues;
        driver_change = props.analytics.driver_change;
        // props.trips.forEach(trip => {
        //     table2Data[trip.transporter] = table2Data[trip.transporter] || {total_issues: 0, total_trips: 0, transporter: trip.transporter}
        //     table2Data[trip.transporter].total_issues = table2Data[trip.transporter].total_issues + trip.issues[0].data.length;
        //     table2Data[trip.transporter].total_trips++;
        //     total_issues += trip.issues[0].data.length;
        //     (trip.issues[0].actions || []).forEach(action => {
        //         if (action.result === 'Driver Change') driver_change++;
        //         subtags[action.subtag] = (subtags[action.subtag] + 1) || 1;
        //     })
        // })
        Object.keys(subtags).forEach(tag => {
            graph.x.push(tag);
            graph.y.push(subtags[tag]);
        })
        const table1Data = [
            {
                key: 'Date',
                value: new Date().toLocaleDateString()
            },
            {
                key: 'Total number of Trips with Issues',
                value: props.totalTrips
            },
            {
                key: 'Total number of Issues',
                value: total_issues
            },
            {
                key: 'Driver Change %',
                value: (driver_change * 100 / props.totalTrips).toFixed(2)
            }
        ];
        setState(state => ({
            ...state,
            table1: {
                ...state.table1,
                data: table1Data
            },
            table2: {
                ...state.table2,
                data: table2Data,
            },
            graph: {
                ...state.graph,
                series: [...graph.y],
                options: {
                    ...state.graph.options,
                    labels: [...graph.x],
                }
            },
        }))
    }, [props])

    return (
        <Grid container style={{height:'100vh'}} alignItems="flex-start" justify="space-evenly">
            <AppBar 
                // className={classes.appBar}
                style={{position: 'relative'}}
            >
                <Toolbar>
                    <Typography variant="h6" 
                        className={classes.title}
                    >
                        Analysis
                    </Typography>
                    <IconButton edge="start" color="inherit" onClick={props.close} aria-label="close">
                    <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Grid item xs={12} className={classes.item}>
                <Grid container justify="space-evenly">
                    <Grid item>
                        <Grid container direction="column">
                            <Grid item>
                                <Paper className={classes.root}>
                                    <TableContainer className={classes.container}>
                                        <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell
                                                colSpan={2}
                                                align="center"
                                                >
                                                    Summary
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {state.table1.data.slice(state.table1.page * state.table1.rowsPerPage, state.table1.page * state.table1.rowsPerPage + state.table1.rowsPerPage).map((row, index) => {
                                            return (
                                                <TableRow hover tabIndex={-1} key={index}>
                                                {(Object.keys(row)).map((key) => (
                                                    <TableCell key={key} align="center">
                                                        {row[key]}
                                                    </TableCell>
                                                ))}
                                                </TableRow>
                                            );
                                            })}
                                        </TableBody>
                                        </Table>
                                    </TableContainer>
                                    {/* <TablePagination
                                        rowsPerPageOptions={[10, 25, 100]}
                                        component="div"
                                        count={rows.length}
                                        rowsPerPage={state.table1.rowsPerPage}
                                        page={state.table1.page}
                                        onChangePage={(e, page) => handleChangePage(e, page, "table1")}
                                        onChangeRowsPerPage={(e) => handleChangeRowsPerPage(e, "table1")}
                                    /> */}
                                </Paper>
                            </Grid>
                            {
                                state.graph.series.length ? 
                                <Grid item className={classes.item}>
                                    <Chart
                                        options={state.graph.options}
                                        series={state.graph.series}
                                        type="pie"
                                        width="550"
                                    />
                                </Grid> :
                                null
                            }
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container> 
                            <Grid item className={classes.item}>
                                <Paper className={classes.root}>
                                    <TableContainer className={classes.container}>
                                        <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell
                                                colSpan={state.table2.headers.length}
                                                align="center"
                                                >
                                                    Transporter wise issues
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                            {state.table2.headers.map((column) => (
                                                <TableCell
                                                    key={column.key}
                                                    align="center"
                                                >
                                                {column.text}
                                                </TableCell>
                                            ))}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {(Object.keys(state.table2.data) || []).slice(state.table2.page * state.table2.rowsPerPage, state.table2.page * state.table2.rowsPerPage + state.table2.rowsPerPage).map((transporter, index) => {
                                            return (
                                                <TableRow hover tabIndex={-1} key={index}>
                                                {state.table2.headers.map((header) => (
                                                    <TableCell key={header.key} align="center">
                                                        {state.table2.data[transporter][header.key]}
                                                    </TableCell>
                                                ))}
                                                </TableRow>
                                            );
                                            })}
                                        </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 25, 100]}
                                        component="div"
                                        count={Object.keys(state.table2.data || []).length}
                                        rowsPerPage={state.table2.rowsPerPage}
                                        page={state.table2.page}
                                        onChangePage={(e, page) => handleChangePage(e, page, "table2")}
                                        onChangeRowsPerPage={(e) => handleChangeRowsPerPage(e, "table2")}
                                    />
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid> 
            </Grid>
        </Grid>
    );
}

export default Analysis;