import React from "react";

import { SvgIcon as Icon, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: theme.typography.pxToRem(20),
  },
  fontSizeSmall: {
    fontSize: theme.typography.pxToRem(16),
  },
  fontSizeLarge: {
    fontSize: theme.typography.pxToRem(24),
  },
}));

const SvgIcon = ({ children, ...otherProps }) => {
  const classes = useStyles();
  return (
    <Icon classes={{ ...classes }} {...otherProps}>
      {children}
    </Icon>
  );
};

export default SvgIcon;
