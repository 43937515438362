import React from "react";
import SvgIcon from "./SvgIcon";

const ChevronRight = ({ color, fontSize, className, style, onClick }) => {
  return (
    <SvgIcon
      style={style}
      color={color}
      fontSize={fontSize}
      className={className}
      onClick={onClick}
      viewBox="0 0 16 16"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.29325 12.2933C3.90225 12.6842 3.90225 13.3162 4.29325 13.7072C4.68425 14.0982 5.31625 14.0982 5.70725 13.7072L10.7072 8.70725C11.0982 8.31625 11.0982 7.68425 10.7072 7.29325L5.70725 2.29325C5.31625 1.90225 4.68425 1.90225 4.29325 2.29325C3.90225 2.68425 3.90225 3.31625 4.29325 3.70725L8.58625 8.00025L4.29325 12.2933Z"
        fill={color || "currentColor"}
      />
    </SvgIcon>
  );
};

export default ChevronRight;
