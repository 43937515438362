const initialState = {
    error: null,
    private: null,
    clientData: {},
    access: false,
    mapData: [],
    loginWithToken: false
};

const reducer = (state = initialState, action) => {
    switch(action.type) {
        case 'LOGGED_IN':
            return {
                ...state,
                private: action.payload.access_token,
                clientData: {
                    client: action.payload.data.user.toUpperCase(),
                    user: action.payload.data.user,
                    priviledge: action.payload.data.priviledge
                },
                access: true
            }
        case 'ERROR':
            return {
                ...state,
                error: action.payload
            }
        case 'LOGOUT':
            return {
                ...state,
                private: null,
                clientData: {},
                access: false,
                loginWithToken: false
            }
        case 'LOGIN_WITH_TOKEN': 
            return {
                ...state,
                private: action.payload.token,
                clientData: {
                    client: action.payload.clientInfo?.client || 'APP USER',
                    user: action.payload.clientInfo?.user || 'App User',
                    write: action.payload.write || 'enabled',
                    priviledge: null
                },
                access: true,
                loginWithToken: true
            }
        default:
            return state;
    }
}

export default reducer;