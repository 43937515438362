import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    counter: {
        margin: '10px 15px 10px 15px',
        height: '123px',
        width: '189px',
        'box-shadow': '0px 6px 20px #27272712',
        'border-radius': '16px',
        'font-family': "'Rubik', sans-serif",
        padding: '15px',
        cursor: 'pointer'
    },
    selectedCounter: {
        margin: '10px 15px 10px 15px',
        height: '123px',
        width: '189px',
        backgroundColor: '#6332CE',
        'box-shadow': '0px 6px 20px #27272712',
        'border-radius': '16px',
        'font-family': "'Rubik', sans-serif",
        padding: '15px',
        color: 'white',
        cursor: 'pointer'
    },
    count: {
        color: '#4949f0'
    },
    selectedCcount: {
        color: 'white'
    }
}))

const iconpack = {
    'All': { img: './img/select-all.png', selectedImg: './img/select-all-selected.png' },
    'Resolved': { img: './img/solved.png', selectedImg: './img/solved-selected.png'  },
    'Unresolved': { img: './img/unsolved.png', selectedImg: './img/unsolved-selected.png'  },
    'Under Monitoring': { img: './img/under-monitoring.png', selectedImg: './img/under-monitoring-selected.png'  },
}

function getIcon(counter) {
    return iconpack[counter];
}

function Counters(props) {

    const classes = useStyles()

    const [state, setState] = React.useState({
        selectedCounter: 'All',
    })

    const changeHandler = (value) => {
        if (value === state.selectedCounter) return;
        setState((state) => ({
            ...state,
            selectedCounter: value
        }));
        props.onCounterClick(value)
    }

    return (
        <Grid container justify="center" alignItems="center" className={classes.container}>
            {
                props.counters.map((counter, index) => (
                    <Grid 
                        item 
                        className={state.selectedCounter === counter.text ? classes.selectedCounter : classes.counter} 
                        key={index} 
                        onClick={() => changeHandler(counter.text)}
                    >
                        <Grid container direction="column" justify="flex-end" style={{
                            backgroundImage: 'url(' + (state.selectedCounter === counter.text ? getIcon(counter.text).selectedImg : getIcon(counter.text).img) + ')',
                            backgroundPosition: 'right top',
                            backgroundRepeat: 'no-repeat',
                            height: '100%'
                        }}>
                            <Grid item className={state.selectedCounter === counter.text ? classes.selectedCcount : classes.count}>
                                {counter.count}
                            </Grid>
                            <Grid item>
                                {counter.text}
                            </Grid>
                        </Grid>
                    </Grid>
                ))
            }
        </Grid>
    )
}

export default Counters;