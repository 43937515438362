import React from "react";
import "./App.css";
import { Route } from "react-router";
import AppBar from "./components/AppBar/AppBar";
import Login from "./components/Login/Login";
import Home from "./components/Home/Home";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import axios from "axios";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";
import "@fontsource/source-sans-pro";

function App(props) {
  const history = useHistory();
  const reduxToken = useSelector((state) => state.private);

  const api = axios.create({
    // baseURL: 'https://xi3w8anfm4.execute-api.ap-south-1.amazonaws.com/api/v2/auth',
    baseURL:
      "https://da93wz82kc.execute-api.ap-south-1.amazonaws.com/api/v1/auth",
  });
  if (
    history.location?.search?.includes("token") &&
    reduxToken !== history.location?.search?.split("token=")[1].split("&")[0]
  ) {
    console.log("logging out");
    props.logout();
  }

  React.useEffect(() => {
    console.log(
      "Hook Running",
      history.location?.search?.split("token=")?.[1]?.split("&")?.[0],
      reduxToken
    );
    if (
      history.location?.search?.includes("token") &&
      reduxToken !==
        history.location?.search?.split("token=")?.[1]?.split("&")?.[0]
    ) {
      let write = "enabled";
      const token = history.location?.search
        ?.split("token=")?.[1]
        ?.split("&")?.[0];
      if (history.location.search.includes("write"))
        write = history.location?.search?.split("write=")?.[1]?.split("&")?.[0];

      api({
        url: "verify",
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            props.loginWithToken({
              token,
              write,
              clientInfo: res.data.result?.[0],
            });
          }
        })
        .catch((err) => {
          console.log(err);
          props?.setError && props.setError(err);
        });
    }
  }, [reduxToken, history.location.search]);

  return (
    <Grid
      container
      direction="column"
      style={{
        height: "100vh",
        flexWrap: "nowrap",
      }}
    >
      <Grid item>{props.loggedInWithToken ? null : <AppBar />}</Grid>
      <Route path="/" exact component={Login} />
      <Route path="/Home" exact component={Home} />
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  loggedInWithToken: state.loginWithToken,
});

const mapDispatchToProps = (dispatch) => ({
  loginWithToken: (data) =>
    dispatch({ type: "LOGIN_WITH_TOKEN", payload: data }),
  logout: () => dispatch({ type: "LOGOUT" }),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
