import React, { useMemo } from "react";
import { Box, Grid, withStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";

import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
  ThreeLevelGroupingCount,
  groupDataWithKey,
  calculateAveragClosingTime,
  TwoLevelGroupingCountAverageClosingTimeCount,
} from "../../../utils";
import Tabs from "./TabsOld";
import useActive from "../../../hooks/useActive";
import MuiTableCell from "@material-ui/core/TableCell";

const TableCell = withStyles({
  root: {
    borderBottom: "none",
    padding: 0,
  },
})(MuiTableCell);

let tabs = [
  { id: 0, label: "Summary", key: "summary" },
  { id: 1, label: "Analytics", key: "analytics" },
];

const Summary = ({ issueData, totalTrips }) => {
  const activeTab = useActive(0);

  const issueCounterDataMapped = useMemo(
    () => ThreeLevelGroupingCount(issueData, "issue_tag", "priority", "status"),
    [issueData]
  );

  const groupedAverageTimeToResolveData = useMemo(
    () =>
      TwoLevelGroupingCountAverageClosingTimeCount(
        issueData,
        "issue_tag",
        "priority"
      ),
    [issueData]
  );

  let dataGroupedbyIssue = groupDataWithKey(issueData, "status");

  const summaryCounters = useMemo(
    () => [
      { id: "totalTrips", label: "Total Trips", count: totalTrips },
      { id: "totalTickets", label: "Total Tickets", count: issueData?.length },
      {
        id: "resolvedTickets",
        label: "Resolved Tickets",
        count: dataGroupedbyIssue?.Resolved?.length || 0,
      },
      {
        id: "unresolvedTickets",
        label: "Unresolved Tickets",
        count: dataGroupedbyIssue?.Unresolved?.length || 0,
      },
      {
        id: "underMonitoring",
        label: "Under Monitoring",
        count: dataGroupedbyIssue?.["Under Monitoring"]?.length || 0,
      },
    ],
    [totalTrips, issueData]
  );
  const analyticsCounters = useMemo(
    () => [
      { id: "totalTrips", label: "Total Trips", count: totalTrips },
      { id: "totalTickets", label: "Total Tickets", count: issueData.length },
      {
        id: "averageTimePaerTicket",
        label: "Average Ticket per Trip",
        count: ((issueData?.length || 0) / (totalTrips || 1)).toFixed(2),
      },
      {
        id: "averageTClosingTime",
        label: "Average Closing Time",
        count: `${calculateAveragClosingTime(issueData)} Hr`,
      },
      {
        id: "resolvedTickets",
        label: "Resolved Tickets",
        count: dataGroupedbyIssue?.Resolved?.length || 0,
        percentage:
          (Number.isNaN(
            dataGroupedbyIssue?.Resolved?.length || 0 / issueData?.length
          )
            ? 0
            : ((dataGroupedbyIssue?.Resolved?.length || 0) /
                issueData?.length) *
              100
          ).toFixed(2) + "%",
      },
      {
        id: "unresolvedTickets",
        label: "Unresolved Tickets",
        count: dataGroupedbyIssue?.Unresolved?.length || 0,
        percentage:
          (Number.isNaN(
            dataGroupedbyIssue?.Unresolved?.length || 0 / issueData?.length
          )
            ? 0
            : ((dataGroupedbyIssue?.Unresolved?.length || 0) /
                issueData?.length) *
              100
          ).toFixed(2) + "%",
      },
      {
        id: "underMonitoring",
        label: "Under Monitoring",
        count: dataGroupedbyIssue?.["Under Monitoring"]?.length || 0,
        // percentage: "47%",
        percentage:
          (Number.isNaN(
            dataGroupedbyIssue?.Unresolved?.length || 0 / issueData?.length
          )
            ? 0
            : ((dataGroupedbyIssue?.["Under Monitoring"]?.length || 0) /
                issueData?.length) *
              100
          ).toFixed(2) + "%",
      },
    ],
    [totalTrips, issueData]
  );

  return (
    <>
      {/* <Tabs
        tabs={tabs}
        size={"medium"}
        initialTabValue={activeTab.active}
        onTabChange={activeTab.handleActive}
        width={"50"}
        sx={{ width: "calc(100%)", minWidth: "50%" }}
      /> */}

      <Tabs
        tabs={tabs?.map((item) => ({
          label: item.label,
          ...item,
          style: {
            minWidth: `calc(100% / ${tabs.length})`,
          },
        }))}
        tab={activeTab.active}
        onTabChange={activeTab.handleActive}
        style={{
          width: "calc(100%)",
        }}
      />
      {/* Content */}
      <Box
        style={{
          height: "calc(100%)",
          overflow: "visible",
          justifyContent: "center",
          alignItems: "center",
          padding: "20px 50px",
          boxSizing: "border-box",
          backgroundColor: "#F5F8FF",
        }}
      >
        {/* Summary */}
        {activeTab.active === 0 && (
          <Grid
            style={{
              height: "100%",
              gap: "16px",
              display: "flex",
              flexDirection: "column",
            }}
            item
            xs={12}
          >
            {/* Summary Counters */}
            <Box
              border={1}
              borderColor="#E3E3E3"
              style={{
                // border: "8px solid #D7E3FE",
                boxSizing: "border-box",
                borderRadius: "8px",
                background: "#fff",
                // border: "8px",
                // borderColor: "#E3E3E3",
                display: "flex",
                height: "calc(46%)",
                width: "calc(100%)",
                padding: "16px",
              }}
            >
              <Grid
                container
                xs={12}
                direction="column"
                style={{
                  width: "calc(100%)",
                  height: "calc(100%)",
                  display: "flex",
                  gap: "8px",
                }}
              >
                {/* Top Text */}
                <Grid item style={{ width: "calc(100%)", height: "calc(15%)" }}>
                  <Typography variant="h4" style={{ color: "#24428A" }}>
                    Live Trips Ticket Summary
                  </Typography>
                </Grid>
                {/* Status Blocks */}
                <Grid
                  container
                  item
                  style={{
                    height: "calc(75%)",
                    width: "calc(97%)",
                    margin: "auto",
                    display: "flex",
                    gap: "24px",
                    justifyContent: "center",
                  }}
                >
                  {summaryCounters.map((item) => (
                    <Grid
                      item
                      style={{
                        minWidth: `calc((100% - 98px) / ${summaryCounters.length})`,
                        height: "calc(100%)",
                      }}
                    >
                      <Box
                        border={1}
                        borderColor="#E3E3E3"
                        style={{
                          boxSizing: "border-box",
                          borderRadius: "8px",
                          backgroundColor: "#F5F8FF",
                          // border: "8px",
                          // borderColor: "#E3E3E3",
                          display: "flex",
                          flexDirection: "column",
                          height: "calc(100%)",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "8px",
                          padding: "16px",
                        }}
                      >
                        <Typography variant="h5" style={{ color: "#666666" }}>
                          {item.label}
                        </Typography>
                        <Typography variant="h2" style={{ color: "#1A1A1A" }}>
                          {item.count}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Box>
            {/* Isuue Stats */}
            <Box
              style={{
                boxSizing: "border-box",
                borderRadius: "8px",
                background: "transparent",
                // borderColor: "#E3E3E3",
                display: "flex",
                minHeight: "calc(70%)",

                width: "calc(100%)",
                paddingTop: "16px",
              }}
            >
              <Grid
                container
                xs={12}
                style={{
                  width: "calc(100%)",
                  minHeight: "calc(100%)",
                  display: "flex",
                  flexDirection: "row",
                  gap: "48px",
                }}
              >
                {Object.entries(issueCounterDataMapped).map(([key, value]) => (
                  <Grid
                    item
                    style={{
                      minWidth: `calc((100% - 96px) / ${3})`,
                      height: "calc(40%)",
                    }}
                  >
                    <Box
                      border={1}
                      borderColor="#E3E3E3"
                      style={{
                        boxSizing: "border-box",
                        borderRadius: "8px",
                        backgroundColor: "#FFFFFF",
                        // border: "8px",
                        // borderColor: "#E3E3E3",
                        display: "flex",
                        flexDirection: "column",
                        height: "calc(100%)",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "8px",
                        padding: "16px",
                      }}
                    >
                      <Grid
                        container
                        direction="column"
                        style={{ height: "calc(100%)", width: `calc(100%)` }}
                      >
                        <Grid
                          item
                          style={{ width: "calc(100%)", height: "calc(20%)" }}
                        >
                          <Typography variant="h4" style={{ color: "#24428A" }}>
                            {key}
                          </Typography>
                        </Grid>
                        <Grid item style={{ height: "calc(80%)" }} p={0}>
                          <Box
                            border={1}
                            borderColor="#E3E3E3"
                            style={{
                              boxSizing: "border-box",
                              borderRadius: "8px",
                              backgroundColor: "#F5F8FF",
                              // border: "8px",
                              // borderColor: "#E3E3E3",
                              display: "flex",
                              flexDirection: "column",
                              height: "calc(100%)",
                              width: "calc(100%)",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: "0px 20px",
                            }}
                          >
                            <SummaryTable tableData={value} />
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
        )}

        {/* Analytics */}
        {activeTab.active === 1 && (
          <Grid
            style={{
              height: "calc(100%)",
              // width: "calc(100%)",
              gap: "24px",
              display: "flex",
              // justifyContent: "center",
              flexDirection: "column",
            }}
            item
            xs={12}
          >
            {/* Analytics Counters */}

            <Box
              border={1}
              borderColor="#E3E3E3"
              style={{
                boxSizing: "border-box",
                borderRadius: "8px",
                background: "#fff",
                // border: "8px",
                // borderColor: "#E3E3E3",
                display: "flex",
                height: "calc(40%)",
                width: "calc(100%)",
                padding: "16px",
              }}
            >
              <Grid
                container
                xs={12}
                direction="column"
                style={{
                  width: "calc(100%)",
                  height: "calc(100%)",
                  display: "flex",
                  gap: "16px",
                }}
              >
                {/* Top Text */}
                <Grid item style={{ width: "calc(100%)", height: "calc(10%)" }}>
                  <Typography variant="h4" style={{ color: "#24428A" }}>
                    Ticket Management Analytics
                  </Typography>
                </Grid>
                {/* Status Blocks */}

                <Grid
                  container
                  item
                  style={{
                    height: "calc(39%)",
                    width: "calc(100%)",
                    display: "flex",
                    gap: "24px",
                    justifyContent: "center",
                  }}
                >
                  {analyticsCounters.slice(0, 4).map((item) => (
                    <Grid
                      item
                      style={{
                        minWidth: `calc((100% - 98px) / ${4})`,
                        height: "calc(100%)",
                      }}
                    >
                      <Box
                        border={1}
                        borderColor="#E3E3E3"
                        style={{
                          boxSizing: "border-box",
                          borderRadius: "8px",
                          backgroundColor: "#F5F8FF",
                          // border: "8px",
                          // borderColor: "#E3E3E3",
                          display: "flex",
                          flexDirection: "column",
                          height: "calc(100%)",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "8px",
                          padding: "16px",
                        }}
                      >
                        <Typography variant="h5" style={{ color: "#666666" }}>
                          {item.label}
                        </Typography>
                        <Typography variant="h2" style={{ color: "#1A1A1A" }}>
                          {item.count}
                        </Typography>
                        {item.percentage && (
                          <Typography
                            variant="subtitle2"
                            style={{ color: "#264CA4" }}
                          >
                            {item.percentage}
                          </Typography>
                        )}
                      </Box>
                    </Grid>
                  ))}
                </Grid>
                <Grid
                  container
                  item
                  style={{
                    height: "calc(39%)",
                    width: "calc(100%)",
                    display: "flex",
                    gap: "24px",
                    justifyContent: "center",
                  }}
                >
                  {analyticsCounters.slice(4).map((item) => (
                    <Grid
                      item
                      style={{
                        minWidth: `calc((100% - 98px) / ${3})`,
                        height: "calc(100%)",
                      }}
                    >
                      <Box
                        border={1}
                        borderColor="#E3E3E3"
                        style={{
                          boxSizing: "border-box",
                          borderRadius: "8px",
                          backgroundColor: "#F5F8FF",
                          // border: "8px",
                          // borderColor: "#E3E3E3",
                          display: "flex",
                          flexDirection: "column",
                          height: "calc(100%)",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "2px",
                          padding: "16px",
                        }}
                      >
                        <Typography variant="h5" style={{ color: "#666666" }}>
                          {item.label}
                        </Typography>
                        <Typography variant="h2" style={{ color: "#1A1A1A" }}>
                          {item.count}
                        </Typography>
                        {item.percentage && (
                          <Typography
                            variant="subtitle2"
                            style={{ color: "#264CA4" }}
                          >
                            {item.percentage}
                          </Typography>
                        )}
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Box>
            {/* Issue Stats */}
            <Box
              // border={1}
              // borderColor="#E3E3E3"
              style={{
                boxSizing: "border-box",
                borderRadius: "8px",
                background: "transparent",
                // border: "8px",
                // borderColor: "#E3E3E3",
                display: "flex",
                minHeight: "calc(50%)",
                width: "calc(100%)",
              }}
            >
              <Grid
                container
                xs={12}
                style={{
                  width: "calc(100%)",
                  minHeight: "calc(100%)",
                  display: "flex",
                  flexDirection: "row",
                  gap: "48px",
                }}
              >
                {Object.entries(issueCounterDataMapped).map(([key, value]) => (
                  <Grid
                    item
                    style={{
                      minWidth: `calc((100% - 96px) / ${3})`,
                      height: "calc(51%)",
                    }}
                  >
                    <Box
                      border={1}
                      borderColor="#E3E3E3"
                      style={{
                        boxSizing: "border-box",
                        borderRadius: "8px",
                        backgroundColor: "#FFFFFF",
                        // border: "8px",
                        // borderColor: "#E3E3E3",
                        display: "flex",
                        flexDirection: "column",
                        height: "calc(100%)",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "8px",
                        padding: "16px",
                      }}
                    >
                      <Grid
                        container
                        direction="column"
                        style={{ height: "calc(100%)", width: `calc(100%)` }}
                      >
                        <Grid
                          item
                          style={{ width: "calc(100%)", height: "calc(20%)" }}
                        >
                          <Typography variant="h4" style={{ color: "#24428A" }}>
                            {key}
                          </Typography>
                        </Grid>
                        <Grid item style={{ height: "calc(80%)" }} p={0}>
                          <Box
                            border={1}
                            borderColor="#E3E3E3"
                            style={{
                              boxSizing: "border-box",
                              borderRadius: "8px",
                              backgroundColor: "#F5F8FF",
                              // border: "8px",
                              // borderColor: "#E3E3E3",
                              display: "flex",
                              flexDirection: "column",
                              height: "calc(100%)",
                              width: "calc(100%)",
                              justifyContent: "center",
                              alignItems: "center",
                              padding: "0px 20px",
                            }}
                          >
                            <AnylyticsTable
                              tableData={value}
                              groupedAverageTimeToResolveData={
                                groupedAverageTimeToResolveData[key]
                              }
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
        )}
      </Box>
    </>
  );
};
export default Summary;

export function SummaryTable({ tableData }) {
  function createData(name, open = 0, close = 0) {
    return { name, open, close };
  }

  const rows = [
    createData("HIGH", tableData?.HIGH?.Open, tableData?.HIGH?.Closed),
    createData("MEDIUM", tableData?.MEDIUM?.Open, tableData?.MEDIUM?.Closed),
    createData("LOW", tableData?.LOW?.Open, tableData?.LOW?.Closed),
  ];
  return (
    <Table
      style={{
        width: "calc(80%)",
        height: "calc(80%)",
        // [`& .${tableCellClasses.root}`]: {
        //   borderBottom: "none",
        // },
        "& .MuiTableCell-root": {
          padding: 0,
        },
        "& .MuiTableCell-paddingNone": {
          padding: 0,
        },
      }}
      aria-label="simple table"
    >
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCell align="center">
            <Typography variant="h5" style={{ color: "#1A1A1A" }}>
              Open
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography variant="h5" style={{ color: "#1A1A1A" }}>
              Close
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row) => (
          <TableRow
            key={row.name}
            // style={{
            //   "&:last-child td, &:last-child th": { border: 0, padding: 0 },
            // }}
          >
            <TableCell component="th" scope="row">
              <Typography variant="h5" style={{ color: "#1A1A1A" }}>
                {row.name}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="body1" style={{ color: "#1A1A1A" }}>
                {row.open}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="body1" style={{ color: "#1A1A1A" }}>
                {row.close}
              </Typography>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export function AnylyticsTable({ tableData, groupedAverageTimeToResolveData }) {
  function createData(name, open = 0, close = 0, avgTime = 0) {
    return { name, open, close, avgTime };
  }

  const rows = [
    createData(
      "HIGH",
      tableData?.HIGH?.Open,
      tableData?.HIGH?.Closed,
      groupedAverageTimeToResolveData?.HIGH
    ),
    createData(
      "MEDIUM",
      tableData?.MEDIUM?.Open,
      tableData?.MEDIUM?.Closed,
      groupedAverageTimeToResolveData?.MEDIUM
    ),
    createData(
      "LOW",
      tableData?.LOW?.Open,
      tableData?.LOW?.Closed,
      groupedAverageTimeToResolveData?.LOW
    ),
  ];
  return (
    <Table
      style={{
        width: "calc(80%)",
        height: "calc(80%)",
        // [`& .${tableCellClasses.root}`]: {
        //   borderBottom: "none",
        // },
        "& .MuiTableCell-root": {
          padding: 0,
        },
        "& .MuiTableCell-paddingNone": {
          padding: 0,
        },
      }}
      aria-label="simple table"
    >
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCell align="center">
            <Typography variant="h5" style={{ color: "#1A1A1A" }}>
              Open
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography variant="h5" style={{ color: "#1A1A1A" }}>
              Close
            </Typography>
          </TableCell>
          <TableCell align="center">
            <Typography variant="h5" style={{ color: "#1A1A1A" }}>
              Avg Time
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row) => (
          <TableRow
            key={row.name}
            // style={{
            //   "&:last-child td, &:last-child th": { border: 0, padding: 0 },
            // }}
          >
            <TableCell component="th" scope="row">
              <Typography variant="h5" style={{ color: "#1A1A1A" }}>
                {row.name}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="body1" style={{ color: "#1A1A1A" }}>
                {row.open}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="body1" style={{ color: "#1A1A1A" }}>
                {row.close}
              </Typography>
            </TableCell>
            <TableCell align="center">
              <Typography variant="body1" style={{ color: "#1A1A1A" }}>
                {row.avgTime}
              </Typography>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
