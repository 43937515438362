import React from "react";
import { Tabs as Tb } from "@material-ui/core";
import Tab from "@material-ui/core/Tab";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    textTransform: "none",
    color: "#666666",
    borderBottom: `1px solid ${theme.palette.secondary.main}`,
    ...theme.typography.h5,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
}));

const Tabs = ({ tabs, onTabChange, tab, ...otherProps }) => {
  const classes = useStyles();
  return (
    <Tb
      value={tab}
      indicatorColor="primary"
      textColor="primary"
      onChange={(e, v) => onTabChange && onTabChange(v)}
      aria-label="disabled tabs example"
      {...otherProps}
    >
      {tabs.map((item) => (
        <Tab {...item} classes={{ ...classes }} />
      ))}
    </Tb>
  );
};

export default Tabs;
