const tripsData = [
  {
    tripId: "63ff9b8aea6ace802c5e5b45",
    user: "FKT",
    time: "2023-03-08T05:03:38.785+0000",
    priority: "MEDIUM",
    status: "Unresolved",
    issue_tag: "Halt",
    issue_value: 0.85,
    actions: [],
  },
  {
    tripId: "64002504a7a17440221304a1",
    user: "FKT",
    time: "2023-03-08T05:03:38.722+0000",
    priority: "LOW",
    status: "Unresolved",
    issue_tag: "Halt",
    issue_value: 0.41,
    actions: [],
  },
  {
    tripId: "64002baaa7a174402213079e",
    user: "FKT",
    time: "2023-03-08T05:03:38.722+0000",
    priority: "LOW",
    status: "Unresolved",
    issue_tag: "Halt",
    issue_value: 0.38,
    actions: [],
  },
  {
    tripId: "64004e660cb343e534a12f99",
    user: "FKT",
    time: "2023-03-08T05:03:38.663+0000",
    priority: "LOW",
    status: "Unresolved",
    issue_tag: "Halt",
    issue_value: 0.39,
    actions: [],
  },
  {
    tripId: "64005430b19d728c259124c2",
    user: "FKT",
    time: "2023-03-08T05:03:38.662+0000",
    priority: "LOW",
    status: "Unresolved",
    issue_tag: "Reverse Movement",
    issue_value: 0.18,
    actions: [],
  },
  {
    tripId: "640061910cb343e534a13788",
    user: "FKT",
    time: "2023-03-08T05:03:38.662+0000",
    priority: "LOW",
    status: "Unresolved",
    issue_tag: "Halt",
    issue_value: 0.41,
    actions: [],
  },
  {
    tripId: "6400b6c21a12e10c8b2d7b7a",
    user: "FKT",
    time: "2023-03-08T05:03:38.564+0000",
    priority: "LOW",
    status: "Unresolved",
    issue_tag: "Halt",
    issue_value: 0.4,
    actions: [],
  },
  {
    tripId: "6400e794a63c1a7d76329f90",
    user: "FKT",
    time: "2023-03-08T05:03:38.562+0000",
    priority: "LOW",
    status: "Unresolved",
    issue_tag: "Halt",
    issue_value: 0.39,
    actions: [],
  },
  {
    tripId: "6400e916a63c1a7d7632a006",
    user: "FKT",
    time: "2023-03-08T05:03:38.110+0000",
    priority: "LOW",
    status: "Unresolved",
    issue_tag: "Halt",
    issue_value: 0.41,
    actions: [],
  },
  {
    tripId: "6400ea3d1a12e10c8b2d8ed6",
    user: "FKT",
    time: "2023-03-08T05:03:38.109+0000",
    priority: "MEDIUM",
    status: "Unresolved",
    issue_tag: "Halt",
    issue_value: 0.85,
    actions: [],
  },
  {
    tripId: "64010d8ea63c1a7d7632ab65",
    user: "FKT",
    time: "2023-03-08T05:03:38.030+0000",
    priority: "LOW",
    status: "Unresolved",
    issue_tag: "Halt",
    issue_value: 0.41,
    actions: [],
  },
  {
    tripId: "64010d99a63c1a7d7632ab69",
    user: "FKT",
    time: "2023-03-08T05:03:38.030+0000",
    priority: "LOW",
    status: "Unresolved",
    issue_tag: "Halt",
    issue_value: 0.38,
    actions: [],
  },
  {
    tripId: "64010ebca63c1a7d7632abb2",
    user: "FKT",
    time: "2023-03-08T05:03:38.029+0000",
    priority: "LOW",
    status: "Unresolved",
    issue_tag: "Halt",
    issue_value: 0.42,
    actions: [],
  },
  {
    tripId: "64010eeda63c1a7d7632abba",
    user: "FKT",
    time: "2023-03-08T05:03:38.029+0000",
    priority: "LOW",
    status: "Unresolved",
    issue_tag: "Halt",
    issue_value: 0.43,
    actions: [],
  },
  {
    tripId: "64010fb8a63c1a7d7632abe4",
    user: "FKT",
    time: "2023-03-08T05:03:38.028+0000",
    priority: "MEDIUM",
    status: "Unresolved",
    issue_tag: "Halt",
    issue_value: 0.83,
    actions: [],
  },
  {
    tripId: "64010fc1a63c1a7d7632abeb",
    user: "FKT",
    time: "2023-03-08T05:03:38.028+0000",
    priority: "LOW",
    status: "Unresolved",
    issue_tag: "Halt",
    issue_value: 0.4,
    actions: [],
  },
  {
    tripId: "640113efa63c1a7d7632ad54",
    user: "FKT",
    time: "2023-03-08T05:03:37.910+0000",
    priority: "LOW",
    status: "Unresolved",
    issue_tag: "Halt",
    issue_value: 0.42,
    actions: [],
  },
  {
    tripId: "64014ca0a63c1a7d7632c4c2",
    user: "FKT",
    time: "2023-03-08T05:03:37.790+0000",
    priority: "LOW",
    status: "Unresolved",
    issue_tag: "Halt",
    issue_value: 0.44,
    actions: [],
  },
  {
    tripId: "63f3c214a2e1dab753f19a4d",
    user: "FKT",
    time: "2023-03-08T05:03:38.727+0000",
    priority: "LOW",
    status: "Unresolved",
    issue_tag: "Halt",
    issue_value: 0.4,
    actions: [],
  },
  {
    tripId: "63f45b5ef13aa8b01d00c055",
    user: "FKT",
    time: "2023-03-08T05:03:38.689+0000",
    priority: "MEDIUM",
    status: "Unresolved",
    issue_tag: "Halt",
    issue_value: 0.83,
    actions: [],
  },
  {
    tripId: "63f5a467542e37f963e6ce6c",
    user: "FKT",
    time: "2023-03-08T05:03:38.687+0000",
    priority: "LOW",
    status: "Unresolved",
    issue_tag: "Halt",
    issue_value: 0.41,
    actions: [],
  },
  {
    tripId: "63f9e6af03a5a5718be1538e",
    user: "FKT",
    time: "2023-03-08T05:03:38.666+0000",
    priority: "MEDIUM",
    status: "Unresolved",
    issue_tag: "Halt",
    issue_value: 0.83,
    actions: [],
  },
  {
    tripId: "64068703bbc14617f41dc53d",
    user: "FKT",
    time: "2023-03-08T05:03:36.862+0000",
    priority: "LOW",
    status: "Unresolved",
    issue_tag: "Halt",
    issue_value: 0.4,
    actions: [],
  },
  {
    tripId: "63c681aca4d372fdb6d0a0f4",
    user: "FKT-Large",
    time: "2023-01-20T23:43:29.014+0000",
    status: "Under Monitoring",
    issue_tag: "Delay",
    issue_value: 50.858514166666666,
    actions: [
      {
        action: "Called Driver",
        result: "Language Problem",
        remark: "Test",
        subtag: "Under Monitoring",
        action2: "",
        result2: "",
        action3: "",
        result3: "",
        createdAt: "2023-01-25T05:28:18.691+0000",
      },
      {
        action: "Called Driver",
        result: "",
        remark: "Testing ",
        subtag: "Other",
        action2: "",
        result2: "",
        action3: "",
        result3: "",
        createdAt: "2023-01-24T06:46:47.039+0000",
      },
      {
        action: "Called Driver",
        result: "Didnt pick the call",
        remark: "",
        subtag: "Other",
        action2: "",
        result2: "",
        action3: "",
        result3: "",
        createdAt: "2023-01-24T06:35:10.491+0000",
      },
    ],
    priority: "HIGH",
  },
  {
    tripId: "63bbd402e732b0bb5cd12775",
    user: "FKT-Large",
    time: "2023-01-15T23:03:28.696+0000",
    status: "Resolved",
    issue_tag: "Halt",
    issue_value: 353.76972222222224,
    actions: [
      {
        action: "Called Driver",
        result: "Call Picked",
        remark: "",
        subtag: "Vehicle Breakdown",
        action2: "",
        result2: "",
        action3: "",
        result3: "",
        createdAt: "2023-02-16T06:43:48.421+0000",
      },
      {
        action: "Called Driver",
        result: "Didnt pick the call",
        remark: "",
        subtag: "Vehicle Breakdown",
        action2: "Called Transporter",
        result2: "Transporter Picked and Gave Number",
        action3: "",
        result3: "",
        createdAt: "2023-02-14T12:08:52.706+0000",
      },
    ],
    priority: "HIGH",
  },
  {
    tripId: "63bbd3e61004ece80230ed5b",
    user: "FKT-Large",
    time: "2023-01-09T23:53:27.959+0000",
    status: "Unresolved",
    issue_tag: "Delay",
    issue_value: 515.4246111111111,
    actions: [
      {
        action: "Called Driver",
        result: "Call Picked",
        remark: "Test",
        subtag: "Vehicle Breakdown",
        action2: "",
        result2: "",
        action3: "",
        result3: "",
        createdAt: "2023-01-27T09:35:35.402+0000",
      },
      {
        action: "Called Driver",
        result: "Didnt pick the call",
        remark: "",
        subtag: "Personal Halt",
        action2: "Called Transporter",
        result2: "Transporter Picked and Confirmed",
        action3: "",
        result3: "",
        createdAt: "2023-01-11T10:08:00.643+0000",
      },
    ],
    priority: "HIGH",
  },
  {
    tripId: "63bbd402e732b0bb5cd12775",
    user: "FKT-Large",
    time: "2023-01-09T23:53:27.958+0000",
    status: "Resolved",
    issue_tag: "Delay",
    issue_value: 822.2930669444445,
    actions: [
      {
        action: "Called Driver",
        result: "Call Picked",
        remark: "",
        subtag: "Vehicle Breakdown",
        action2: "",
        result2: "",
        action3: "",
        result3: "",
        createdAt: "2023-02-16T06:43:48.421+0000",
      },
      {
        action: "Called Driver",
        result: "Didnt pick the call",
        remark: "",
        subtag: "Vehicle Breakdown",
        action2: "Called Transporter",
        result2: "Transporter Picked and Gave Number",
        action3: "",
        result3: "",
        createdAt: "2023-02-14T12:08:52.706+0000",
      },
    ],
    priority: "HIGH",
  },
  {
    tripId: "63b5273c24269f90aeb56ebe",
    user: "FKT-Large",
    time: "2023-01-04T07:33:27.328+0000",
    status: "Unresolved",
    issue_tag: "Not Tracked",
    issue_value: 123.32286444444445,
    actions: [
      {
        action: "Called Driver",
        result: "",
        remark: "",
        subtag: "",
        action2: "",
        result2: "",
        action3: "",
        result3: "",
        createdAt: "2023-01-09T13:02:50.342+0000",
      },
      {
        action: "Called Driver",
        result: "Didnt pick the call",
        remark: "",
        subtag: "",
        action2: "Called Transporter",
        result2: "Transporter Picked",
        action3: "",
        result3: "",
        createdAt: "2023-01-09T09:10:05.695+0000",
      },
    ],
    priority: "HIGH",
  },
  {
    tripId: "6366af2c2a984245a9a97923",
    user: "FKT",
    time: "2022-11-23T05:43:30.107+0000",
    status: "Resolved",
    issue_tag: "Delay",
    issue_value: 377.10636444444447,
    actions: [
      {
        action: "Called Driver",
        result: "Call Picked",
        remark: "",
        subtag: "No Entry",
        action2: "",
        result2: "",
        action3: "",
        result3: "",
        createdAt: "2022-11-23T07:01:46.799+0000",
      },
      {
        action: "Pre Closed",
        result: "",
        remark: "",
        subtag: "Other",
        action2: "",
        result2: "",
        action3: "",
        result3: "",
        createdAt: "2022-11-23T06:22:39.790+0000",
      },
    ],
    priority: "HIGH",
  },
  {
    tripId: "6366af2c2a984245a9a97923",
    user: "FKT",
    time: "2022-11-23T04:23:31.239+0000",
    status: "Resolved",
    issue_tag: "Halt",
    issue_value: 2.983611111111111,
    actions: [
      {
        action: "Called Driver",
        result: "Call Picked",
        remark: "",
        subtag: "No Entry",
        action2: "",
        result2: "",
        action3: "",
        result3: "",
        createdAt: "2022-11-23T07:01:46.799+0000",
      },
      {
        action: "Pre Closed",
        result: "",
        remark: "",
        subtag: "Other",
        action2: "",
        result2: "",
        action3: "",
        result3: "",
        createdAt: "2022-11-23T06:22:39.790+0000",
      },
    ],
    priority: "HIGH",
  },
  {
    tripId: "6366af2c2a984245a9a97923",
    user: "FKT",
    time: "2022-11-17T09:43:31.126+0000",
    status: "Resolved",
    issue_tag: "Delay",
    issue_value: 266.47719166666667,
    actions: [
      {
        action: "Called Driver",
        result: "Call Picked",
        remark: "",
        subtag: "No Entry",
        action2: "",
        result2: "",
        action3: "",
        result3: "",
        createdAt: "2022-11-23T07:01:46.799+0000",
      },
      {
        action: "Pre Closed",
        result: "",
        remark: "",
        subtag: "Other",
        action2: "",
        result2: "",
        action3: "",
        result3: "",
        createdAt: "2022-11-23T06:22:39.790+0000",
      },
      {
        action: "Called Driver",
        result: "Call Picked",
        remark: "",
        subtag: "Reached Destination",
        action2: "",
        result2: "",
        action3: "",
        result3: "",
        createdAt: "2022-11-17T12:19:41.431+0000",
      },
    ],
    priority: "HIGH",
  },
  {
    tripId: "6370ae03c8db87167803df44",
    user: "FKT",
    time: "2022-11-16T04:23:29.277+0000",
    status: "Resolved",
    issue_tag: "Delay",
    issue_value: 13.372534166666666,
    actions: [
      {
        action: "Called Driver",
        result: "Call Picked",
        remark: "",
        subtag: "Other",
        action2: "",
        result2: "",
        action3: "",
        result3: "",
        createdAt: "2022-11-16T06:35:11.896+0000",
      },
      {
        action: "Called Driver",
        result: "Call Picked",
        remark: "",
        subtag: "Personal Halt",
        action2: "",
        result2: "",
        action3: "",
        result3: "",
        createdAt: "2022-11-16T06:34:05.476+0000",
      },
    ],
    priority: "HIGH",
  },
  {
    tripId: "6370ae03c8db87167803df44",
    user: "FKT",
    time: "2022-11-16T04:03:29.845+0000",
    status: "Resolved",
    issue_tag: "Halt",
    issue_value: 2.8955555555555557,
    actions: [
      {
        action: "Called Driver",
        result: "Call Picked",
        remark: "",
        subtag: "Other",
        action2: "",
        result2: "",
        action3: "",
        result3: "",
        createdAt: "2022-11-16T06:35:11.896+0000",
      },
      {
        action: "Called Driver",
        result: "Call Picked",
        remark: "",
        subtag: "Personal Halt",
        action2: "",
        result2: "",
        action3: "",
        result3: "",
        createdAt: "2022-11-16T06:34:05.476+0000",
      },
    ],
    priority: "HIGH",
  },
];

export const ThreeLevelGroupingCount = (data, level1, level2, level3) => {
  const groupedData = data.reduce((acc, item) => {
    // Group by by level1
    acc[item[level1]] = acc[item[level1]] || {};
    // Group by by level2
    acc[item[level1]][item[level2]] = acc[item[level1]][item[level2]] || {};
    // Group by level 3 and assign count instead of data
    const type = item[level3] === "Resolved" ? "Closed" : "Open";
    acc[item[level1]][item[level2]][type] =
      (acc[item[level1]][item[level2]][type] || 0) + 1;
    return acc;
  }, {});
  return groupedData;
};

export const groupDataWithKey = (data = [], key) =>
  data.reduce((a, b) => {
    const value = String(b[key]);
    if (value in a) {
      a[value].push(b);
    } else {
      a[value] = [b];
    }
    return a;
  }, {});

export const issueCounterData = ThreeLevelGroupingCount(
  tripsData,
  "issue_tag",
  "priority",
  "status"
);

export const calculateAveragClosingTime = (data = []) => {
  let tripIdwise = groupDataWithKey(data, "tripId");
  console.log(data);

  // sort Tripwise Data by time
  const sortedTripsByTime = Object.keys(tripIdwise).reduce((acc, curr) => {
    // console.log(curr);
    const sortedData = tripIdwise[curr];
    sortedData.sort((a, b) => new Date(a.time) - new Date(b.time));
    acc[curr] = sortedData;
    return acc;
  }, {});

  console.log(sortedTripsByTime);
  let totalTimeToResolved = 0,
    resolvedCounter = 0;
  Object.entries(sortedTripsByTime).forEach(([key, value]) => {
    // console.log(key);
    var tUnresolved = 0,
      tResolved = 0,
      timeTakenToResolved = 0;
    value.forEach((item) => {
      // console.log(item.status);
      if (item.status === "Unresolved" && tUnresolved === 0) {
        tUnresolved = item.time;
      } else if (item.status === "Resolved" && tResolved === 0) {
        // console.log("here");
        resolvedCounter++;
        tResolved = item.time;
        if (tResolved !== 0 && tUnresolved !== 0)
          timeTakenToResolved = (
            (new Date(tResolved).valueOf() - new Date(tUnresolved).valueOf()) /
            1000 /
            60 /
            60
          ).toFixed(1);

        totalTimeToResolved += parseInt(timeTakenToResolved);

        // reseting when resolved is found
        tUnresolved = 0;
        tResolved = 0;
      }
    });
  });
  // console.log({ totalTimeToResolved, resolvedCounter });
  return Number.isNaN(totalTimeToResolved / resolvedCounter)
    ? 0
    : totalTimeToResolved / resolvedCounter;
};

export const TwoLevelGroupingCountAverageClosingTimeCount = (
  data,
  level1,
  level2
) => {
  const groupedData = data.reduce((acc, item) => {
    // console.log(item);
    // Group by by level1
    acc[item[level1]] = acc[item[level1]] || {};
    // Group by by level2
    acc[item[level1]][item[level2]] = acc[item[level1]][item[level2]] || [];
    // calculateAveragClosingTime(acc[item[level1]][item[level2]]) || {};
    acc[item[level1]][item[level2]].push(item);
    return acc;
  }, {});

  // sending reduce array to function and replacing it with average closing time counter
  Object.entries(groupedData).forEach(([key1, value1]) => {
    Object.entries(value1).forEach(([key2, value2]) => {
      groupedData[key1][key2] = calculateAveragClosingTime(value2);
    });
  });

  console.log(groupedData);
  return groupedData;
};
