import React from 'react'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import PersonIcon from '@material-ui/icons/Person';
import { connect } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import Grid from '@material-ui/core/Grid';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useHistory } from "react-router-dom";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      height: '64px'
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
      color: 'white'
    },
    bgColor: {
        backgroundColor: '#054A91'
    }
}));


function Appbar(props) {

    let history = useHistory();

    function logout() {
      props.logout();
      history.push('/');
    }


    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
      setOpen(prevOpen => !prevOpen);
    };

    const handleClose = event => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }
      setOpen(false);
    };
    function handleListKeyDown(event) {
      if (event.key === 'Tab') {
        event.preventDefault();
        setOpen(false);
      }
    }

    const prevOpen = React.useRef(open);
    React.useEffect(() => {
      if(props.access)
      if (props.access && prevOpen.current === true && open === false) {
        anchorRef.current.focus();
      }

      prevOpen.current = open;
    }, [open]);// eslint-disable-line react-hooks/exhaustive-deps

    const classes = useStyles();
    return (
        <AppBar position="static" className={classes.root + ' ' + classes.bgColor}>
            <Toolbar>
                <Typography variant="h6" className={classes.title}>
                CONTROL TOWER | <span style={{fontWeight: 'normal'}}>Intutrack</span>
                </Typography>
                {
                  props.access ? 
                  (<React.Fragment>
                  <Button 
                  ref={anchorRef}
                  aria-controls={open ? 'menu-list-grow' : undefined}
                  aria-haspopup="true"
                  onClick={handleToggle}>
                    <PersonIcon style={{color:'white'}}/>
                    <ExpandMoreIcon style={{color:'white'}}/>
                  </Button> 
                  <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                              {/* <MenuItem onClick={handleClose}>Profile</MenuItem> */}
                              <MenuItem onClick={handleClose}><PersonIcon style={{margin: '5px'}} color="primary"/>
                              <Grid container direction="column">
                              <Grid item>
                                  {props.user}
                              </Grid>
                              <Grid item>
                              <Typography variant="overline" display="block" gutterBottom>
                                {props.client}
                              </Typography>
                              </Grid>
                              </Grid>
                              </MenuItem>
                              <MenuItem onClick={(e) => {
                                handleClose(e);
                                logout()
                                }}><ExitToAppIcon style={{margin: '5px'}} color="primary"/>Logout</MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                  </React.Fragment>) : null
                
                }
            </Toolbar>
        </AppBar>
    );
}

const mapStateToProps = (state) => ({
    access: state.access,
    client: state.clientData.client,
    user: state.clientData.user
})

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch({type:'LOGOUT'})
})

export default connect(mapStateToProps, mapDispatchToProps)(Appbar);